<template>
  <div class="not-found" id="not-found">
    <div class="container">
      <div class="box">
        <div class="content">
          <h2>4<span>0</span>4</h2>
          <h3>PAGE NOT FOUND</h3>
          <p>The page you are looking for was moved, removed, rename or might never existed.</p>
        </div>
        <router-link to="/" class="go-home">BACK TO HOME</router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFoundView",
  methods: {},
  beforeMount() {},
  mounted() {},
  components: {},
};
</script>

<style>
/* Box */
.not-found .box{
  padding: 4rem 0;
  width: 50%;
  margin: 0 auto;
  text-align: center;
}
.not-found .box .content{
  background-color: #F7F6E2;
  border-radius: 10px;
  padding: 3rem 2rem;
}
.not-found .box .content h2{
  color: #6F825F;
  font-size: 8rem;
  font-weight: bold;
  margin-bottom: 2rem;
}
.not-found .box .content h2 span{
  color: #B2CC9B;
}
.not-found .box .content h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.not-found .box .content p{
  color: #6F825F;
  font-size: 16px;
}
.not-found .box .go-home{
  background-color: #B2CC9B;
  text-decoration: none;
  display: block;
  margin-top: 1rem;
  border-radius: 10px;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
  padding: .8rem 0;
}
@media (max-width: 992px){
  .not-found .box{
    width: 60%;
  }
}
@media (max-width: 767px){
  .not-found .box{
    width: 70%;
  }
  .not-found .box .content h2{
    font-size: 7rem;
  }
}
@media (max-width: 400px){
  .not-found .box{
    width: 80%;
  }
  .not-found .box .content{
    padding: 3rem 1rem;
  }
  .not-found .box .content h2{
    font-size: 6rem;
  }
}
@media (max-width: 300px){
  .not-found .box{
    width: 90%;
  }
  .not-found .box .content h2{
    font-size: 5rem;
  }
}
</style>