<template>
  <div class="contact-us" id="contact-us">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('CONTACT US') }}</h3>
    </div>
    <div class="container">
      <div class="info">
        <h1>{{ $t('Reach out') }}<br/>{{ $t('& touch us') }}</h1>
        <div class="card1">
          <div class="phone">
            <h3>{{ $t('Send message on whatsapp') }}</h3>
            <a href="tel:965-222-2222">+ (965) 22222222</a>
          </div>
          <img src="@/assets/icons/contactus/whatsapp.svg" alt="whatsapp icon">
        </div>
        <div class="card1">
          <div class="email">
            <h3>{{ $t('Send us a email') }}</h3>
            <a href="mailto:info@tinykoala.com">info@tinykoala.com</a>
          </div>
          <img src="@/assets/icons/contactus/email.svg" alt="whatsapp icon">
        </div>
      </div>
      <div class="contact-form">
        <div class="form">
          <h1>{{ $t('Send us') }}<br/> {{ $t('a message') }}</h1>
          <form v-on:submit.prevent="sendMessage">
            <label>{{$t('Full Name')}}*</label>
            <input type="text" v-model="data.name" :placeholder="$t('Enter Here')" required>
            <label>{{$t('Email')}}*</label>
            <input type="text" v-model="data.email" :placeholder="$t('Enter Here')" required>
            <label>{{$t('Mobile Number')}}*</label>
            <input type="text" v-model="data.phone" :placeholder="$t('Enter Here')" required>
            <label>{{$t('Message')}}*</label>
            <input type="text" v-model="data.message" :placeholder="$t('Enter Here')" required>

            <input class="submit-btn" type="submit" value="Send" :disabled="!!isLoading">

          </form>
          <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal"
                  style="display: none;"></button>
          <MessageModal :content="modalMessage"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>

import store from '@/store'
import MessageModal from '@/components/global/MessageModal.vue'
import langStor from '@/langStore'

export default {
  name: "ContactUsView",
  data() {
    return {
      data: {
        name: '',
        email: '',
        phone: '',
        message: '',
      },
      errorMessage: '',
      modalMessage: '',
      isLoading: false
    }
  },
  methods: {
    getUserInfo() {
      if (store.state.accessToken !== '') {
        fetch(this.serverURL + 'api/auth/my-account', {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
        })
            .then(response => response.json())
            .then(data => {
              if (data.status === 200) {
                this.data.name = data.data.name;
                this.data.email = data.data.email;
                this.data.phone = data.data.phone;
              }
            })
            .catch(error => {
              console.log(error);
            })
      }
    },
    sendMessage() {
      fetch(this.serverURL + 'api/messages', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(this.data)
      })
          .then(response => response.json())
          .then(data => {
            if (data.status === 201) {
              window.$('.contact-us #openModal').click();

              this.errorMessages = [];
              this.modalMessage = 'Message Sent. Thanks for contacting!';
            } else {
              let messages = [];

              for (let propt in data.errors) {
                messages.push(data.errors[propt].join(' '));
              }
              this.errorMessages = messages;
            }
          })
          .catch(error => {
            console.log(error.message);
          })
    }
  },
  beforeMount() {
    this.getUserInfo();
  },
  mounted() {
  },
  components: {
    MessageModal,
  },
  computed: {
    language(){
      return langStor.state.language
    }
  },
};
</script>


<style scoped>
.contact-us .background {
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}

.contact-us .background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
[data-direction = rtl] .contact-us {
    direction: ltr !important;
}
[data-direction = rtl] .phone {
    text-align: right !important;
}

.contact-us .background h3 {
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.contact-us .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px;
}

.contact-us .phone,
.contact-us .email {
  text-align: left;
  width: 80%;
}
[data-direction = rtl] .contact-us .email{
    text-align: right !important;
}

.contact-us .info,
.contact-us .contact-form {
  width: 45%;
}

.contact-us .form {
  text-align: left;
}
[data-direction = rtl] .contact-us .form{
    text-align: right !important;
}

.contact-us .form h1,
.contact-us .info h1 {
  font-size: 32px;
  font-weight: bold;
  color: #6F825F;
  margin: 2rem 0;
  text-align: left;

}
[data-direction = rtl] .contact-us .info h1{
    text-align: right !important;
}
[data-direction = rtl] .contact-us .form h1{
    text-align: right !important;
}

.contact-us .card1 {
  border: 1px solid #9B9A9A;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.contact-us .card1 h3 {
  font-size: 20px;
  color: black;
  margin-bottom: 5px;
}

.contact-us .card1 a {
  color: #B2CC9B;
  text-decoration: none;
  font-size: 16px;

}

.contact-us .card1 img {
  width: 52px;
  height: 52px;
  margin-left: 10px;
}

.contact-us .form h1 {
  margin-bottom: 20px;
}

.contact-us .form label {
  color: #6F825F;
  font-size: 14px;
  display: block;
  margin: .5rem 0;
}

.contact-us .form label:not(:first-of-type) {
  margin-top: 2rem;
}

.contact-us .form input[type="text"] {
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  outline: none;
  padding: .8rem 2rem;
  border-radius: 10px;
}

.contact-us .form input::placeholder {
  color: #A2A2A2;
  font-size: 14px;
  font-weight: lighter;
}

.contact-us .form .submit-btn {
  width: 100%;
  padding: 10px;
  background-color: #B2CC9B;
  font-size: 18px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-top: 2rem;
}

.contact-us .form .submit-btn:disabled {
  background-color: #ccc;
}

.contact-us .error-message p {
  color: red;
}

/* Responsive styles */
@media (max-width: 1200px) {
  .contact-us .container {
    flex-direction: column;
  }

  .contact-us .info,
  .contact-us .contact-form {
    width: 100%;
  }

  .contact-us .info {
    margin-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .contact-us .form,
  .contact-us .info {
    width: 80%;
    margin: 0 auto;
  }
}

@media (max-width: 767px) {
  .contact-us .form,
  .contact-us .info {
    width: 90%;
  }
}

@media (max-width: 400px) {
  .contact-us .form,
  .contact-us .info {
    width: 95%;
  }
}
</style>