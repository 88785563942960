<template>
  <div class="home-category" id="home-category">
    <div class="container">
      <h2>{{ $t('Our Categories') }}</h2>
      <p v-html="text?.content"></p>
      <div class="items">
        <div class="row">
          <div class="col-4" v-for="category in categories" :key="category.id">
            <div class="item" :data-id="category.id" :data-name="category.name">
              <img :style="{width: category.width!=null ? `${category.width}px !important` : '',height: category.height!=null ? `${category.height}px !important` : ''}" :src="category.image" alt="Girl Image">
              <h3>{{ category?.translations[0]?.locale.includes(language)?category?.translations[0]?.name:category?.translations[1]?.locale.includes(language)?category?.translations[1]?.name:category?.name  }}</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'
import langStor from '@/langStore'

export default {
  name: "HomeCategorySection",
  props:['home'],
  data() {
    return {
      categories: [],
    }
  },
  methods: {
    getCategories() {
      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            this.categories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  computed:{
    text(){
      return this.home?.translations ? this.home.translations.filter(item=>{
        return item.name == 'categories' && item.locale == langStor.state.language
      })[0] : '';
    },
    language(){
      return langStor.state.language
    }
  },
  beforeMount() {
    this.getCategories();
  },
  mounted() {
    let that = this;

    // Item Clicked
		window.$('body').on('click', '.home-category .items .item', function (){
      localStorage.setItem('category_id', window.$(this).data('id'));
      
      let fullPath = '/Category/' + window.$(this).data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });
  },
  components: {
  },
};
</script>

<style scoped>
.home-category {
  padding: 2rem 0;
}

.home-category h2 {
  font-size: 42px;
  color: black;
  font-weight: bold;
  margin-bottom: 1rem;
}

.home-category p {
  font-size: 16px;
  color: #4B4B4B;
  width: 40%;
  margin: 0 auto 2rem;
}

.home-category .items .row {
  align-items: center;
  justify-content: center;
}

.home-category .items a {
  text-decoration: none;
}

.home-category .items .item{
  cursor: pointer;
}

.home-category .items .item img {
  width: 20rem !important;
}

.home-category .items .item h3 {
  color: black;
  font-weight: bold;
  font-size: 24px;
  margin: 1rem 0;
}

@media(max-width: 1200px) {
  .home-category .items .item img {
    width: 18rem !important;
  }
}

@media(max-width: 992px) {
  .home-category p {
    width: 60%;
  }

  .home-category .items .item img {
    width: 14rem !important;
  }
}

@media(max-width: 767px) {
  .home-category p {
    width: 80%;
  }

  .home-category .items .item img {
    width: 10rem !important;
  }
}

@media(max-width: 500px) {
  .home-category h2 {
    font-size: 35px;
  }

  .home-category p {
    width: 90%;
  }

  .home-category .items .item img {
    width: 8rem !important;
  }

  .home-category .items .item h3 {
    font-size: 20px;
  }
}

@media(max-width: 400px) {
  .home-category h2 {
    font-size: 30px;
  }

  .home-category p {
    width: 100%;
  }

  .home-category .items .item img {
    width: 6rem !important;
  }

  .home-category .items .item h3 {
    font-size: 18px;
  }
}

@media(max-width: 300px) {
  .home-category h2 {
    font-size: 22px;
  }

  .home-category p {
    width: 100%;
    font-size: 12px;
  }

  .home-category .items .item img {
    width: 5rem !important;
  }

  .home-category .items .item h3 {
    font-size: 12px;
  }
}

@media(max-width: 250px) {
  .home-category .items .item img {
    width: 4rem !important;
  }

  .home-category .items .item h3 {
    font-size: 10px;
  }
}
</style>