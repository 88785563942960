<template>
  <div class="delete-address-modal" id="delete-address-modal">
    <!-- Modal -->
    <div
        class="modal fade"
        id="DeleteAddressModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ $t('delete address') }}</h5>
          </div>
          <div class="modal-body">
            {{ $t('Are you sure want to delete address?') }}
          </div>
          <div class="modal-footer">
            <button
                type="button"
                class="no-btn"
                data-bs-dismiss="modal"
                aria-label="Close"
            >{{ $t('No') }}</button>
            <button
                type="button"
                class="yes-btn"
                @click="deleteAddress"
                data-bs-dismiss="modal"
                aria-label="Close"
            >{{ $t('Yes') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import store from "@/store";

export default {
  name: "DeleteAddressView",
  data() {
    return {
    }
  },
  methods: {
    deleteAddress() {
      fetch(this.serverURL + 'api/addresses/' + localStorage.getItem('addressId') ,{
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            if(data.status === 200)
              location.reload();
          })
          .catch(error => {
            console.error(error);
          })
    }
  },
  watch:{
  },
  computed: {
  },
};
</script>

<style>
.delete-address-modal .modal{
  background-color: #E7E7E7;
  padding-top: 30vh;
}
.delete-address-modal .modal .modal-content {
  border-radius: 10px;
  padding: 2rem 1.5rem;
  border: none;
}
.delete-address-modal .modal .modal-header{
  border-bottom: none;
  padding: 0;
}
.delete-address-modal .modal .modal-title {
  color: #6F825F;
  font-size: 18px;
}
.delete-address-modal .modal .modal-body{
  text-align: left;
  margin-top: .5rem;
  color: #1E181A;
  font-size: 15px;
  padding: 0;
}
.delete-address-modal .modal .modal-footer{
  padding: 0;
  border-top: 0;
  margin-top: 1rem;
}
.delete-address-modal .modal .yes-btn,
.delete-address-modal .modal .no-btn{
  border: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  padding: .7rem 2rem;
}
.delete-address-modal .modal .yes-btn{
  color: #FFF;
  background-color: #B2CC9B;
}
.delete-address-modal .modal .no-btn{
  color: #000;
  background-color: transparent;
}
</style>
