<template>
  <div class="home-explain" id="home-explain">
    <div class="container">
      <Carousel >
        <Slide v-for="slide in slides" :key="slide.id" >
          <div class="carousel__item">
            <div class="row">
              <div class="col-6">
                <div class="content">
                  <h1>{{ slide.translations[0]?.locale.includes(language)?slide.translations[0].title:slide?.translations[1]?.locale.includes(language)?slide.translations[1]?.title:slide.title }}</h1>
                  <p>{{ slide.translations[0].locale.includes(language)?slide.translations[0].description:slide.translations[1].locale.includes(language)?slide.translations[1].title:slide.description  }}</p>
                  <router-link v-if="slide.sliderable_type" :to=slideLink(slide) >{{ $t('EXPLORE') }}</router-link>
                </div>
              </div>
              <div class="col-6">
                <div class="image">
                  <img :src="slide.image" alt="Explain Image">
                  <img class="sun-img" src="@/assets/icons/home/sun.svg" alt="Sun Icon">
                </div>
              </div>
            </div>
          </div>
        </Slide>
        <template #addons>
          <Pagination />
        </template>
      </Carousel>      
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import store from '@/store'
import langStor from '@/langStore'

export default {
  name: "HomeExplainSection",
  data() {
    return {
      slides: [],
    }
  },
  computed:{
    language(){
      return langStor.state.language
    }
  },
  methods: {
    getSliders() {
      fetch(this.serverURL + 'api/sliders', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            if (data.status == 200)
              this.slides = data.data;
          })
          .catch(error => {
            console.log(error);
          })
    },
    slideLink(slide){
      if(slide.sliderable_type == 'product')
          return '/Product/' + slide.sliderable_object.name;
      else if(['sub_category', 'category'].includes(slide.sliderable_type))
          return '/Category/' + slide.sliderable_object.name;
      else
          return '/';
    }
  },
  beforeMount() {
    this.getSliders()
  },
  mounted() {},
  components: {
    Carousel,
    Slide,
    Pagination,
  },
};
</script>

<style>
[data-direction = rtl] .carousel__item  {
    direction: rtl !important;
}
[data-direction = rtl] .content  {
    text-align: right !important;
}
.home-explain{
  background-color: #F7F6E2;
  padding: 2rem 0;
}
.home-explain .row{
  align-items: center;
}

/* Content */
.home-explain .content{
  text-align: left;
  max-width: 25rem;
}
.home-explain .content h1{
  font-size: 48px;
  font-weight: 500;
  color: #6F825F;
}
.home-explain .content p{
  font-size: 16px;
  font-weight: 400;
  color: #4B4B4B;
}
.home-explain .content a{
  text-decoration: none;
  color: #FFF;
  border-radius: 15px;
  background-color: #B2CC9B;
  padding: .5rem 0;
  max-width: 8rem;
  display: block;
  text-align: center;
  font-size: 16px;
}
/* Image */
.home-explain .image{
  position: relative;
}
.home-explain .image img{
  width: 82%;
}
.home-explain .image .sun-img{
  width: 8rem;
  position: absolute;
  bottom: 6rem;
  right: 0;
}
.carousel__pagination-button::after{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
@media(min-width: 992px) and (max-width: 1200px){
  .home-explain .image .sun-img{
    width: 6rem;
    bottom: 6rem;
  }
}
@media(min-width:767px) and (max-width: 992px){
  .home-explain .image .sun-img{
    width: 5rem;
    bottom: 4rem;
  }
  .home-explain .content h1{
    font-size: 43px;
  }
  .home-explain .content p{
    font-size: 13px;
  }
}
@media(max-width: 767px){
  .home-explain .image .sun-img{
    width: 4rem;
    bottom: 3rem;
  }
  .home-explain .content h1{
    font-size: 31px;
  }
  .home-explain .content p{
    font-size: 10px;
  }
  .home-explain .content a {
    max-width: 6rem;
    font-size: 12px;
  }
}
@media(max-width: 500px){
  .home-explain .image .sun-img{
    width: 3rem;
    bottom: 2.5rem;
  }
  .home-explain .content h1{
    font-size: 25px;
  }
  .home-explain .content p{
    font-size: 9px;
  }
}
@media(max-width: 400px){
  .home-explain .image .sun-img{
    width: 2.5rem;
    bottom: 2.5rem;
  }
  .home-explain .content h1{
    font-size: 22px;
  }
  .home-explain .content p{
    font-size: 7px;
  }
  .home-explain .content a {
    max-width: 5rem;
    font-size: 10px;
  }
}
@media(max-width: 300px){
  .home-explain .image .sun-img{
    width: 2rem;
    bottom: 1.5rem;
  }
  .home-explain .content h1{
    font-size: 14px;
  }
  .home-explain .content p{
    font-size: 6px;
  }
  .home-explain .content a {
    max-width: 4rem;
    font-size: 8px;
  }
}
</style>