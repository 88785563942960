<template>
  <div class="account-address" id="account-address">
    <Loading v-model:active="isLoading"
             :can-cancel="true"
             :is-full-page="false"
             color="#B2CC9B"
             loader="bars"
             background-color="#000"
             :lock-scroll="false"
    />
    <div class="address">
      <h3>{{$t('My Address')}}</h3>
      <button data-bs-toggle="modal" data-bs-target="#addAddressModal">{{$t('Add New Address')}}</button>
      <div v-if="addresses.length > 0" class="cards">
        <div class="row">
          <div class="col-md-6" v-for="address in addresses" :key="address.id">
            <div class="card" :data-id="address.id" :data-charge="address.area?address.area.deliver_charge:'0'">
              <div class="heading">
                <h4>{{ address.title }}</h4>
              </div>
              <span>{{ address.area ? address.area.name : '' }}</span>
              <p>{{ $t('Block') }} {{ address.block }}, {{ $t('Street') }} {{ address.street }}, {{ $t('Avenue') }} {{ address.avenue }}.</p>
              <div class="actions">
                <button type="button" class="delete-button" @click="setAddressId(address.id)" data-bs-toggle="modal" data-bs-target="#DeleteAddressModal">
                  {{ $t('Delete') }}
                </button>
                <button @click="current_id=address" type="button" class="edit-button" data-bs-toggle="modal" data-bs-target="#editAddressModal">
                  {{ $t('Edit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/icons/empty-logo.svg" alt="Empty Logo"/>
        <p>{{ $t('No Saved Address') }}.<br/>{{ $t('Please Save New Address!') }}</p>
      </div>
    </div>
    <add-address-modal/>
    <edit-address-modal :address="current_id"/>
    <delete-address-modal/>

  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import store from "@/store";
import AddAddressModal from '@/components/Address/AddAddressModal.vue'
import EditAddressModal from '@/components/Address/EditAddressModal.vue'
import DeleteAddressModal from '@/components/Address/DeleteAddressModal.vue'

export default {
  name: "addressSection",
  data() {
    return {
      addresses: [],
      addressId: -1,
      isLoading: false,
      current_id:{}
    }
  },
  methods: {
    setAddressId(addressId) {
      localStorage.setItem('addressId',addressId);
      this.addressId = addressId;
    },
    getAddresses() {
      this.isLoading = true;
      fetch(this.serverURL + 'api/my-addresses', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;
            if (data.status === 200)
              this.addresses = data.data;
          })
          .catch(error => {
            console.log(error);
          })
    },
  },
  beforeMount() {
    this.getAddresses();
  },
  mounted() {
    // On Toggle Modal
    window.$('#addAddressModal')
        .on('hide.bs.modal', function () {
          window.$('html').css('overflow', 'initial');
        })
        .on('show.bs.modal', function () {
          window.$('html').css('overflow', 'hidden');
        });

    window.$('#EditAddressModal')
        .on('hide.bs.modal', function () {
          window.$('html').css('overflow', 'initial');
        })
        .on('show.bs.modal', function () {
          window.$('html').css('overflow', 'hidden');
        });

  },
  components: {
    Loading,
    AddAddressModal,
    EditAddressModal,
    DeleteAddressModal
  },
};
</script>
<style>


.account-address {
  text-align: left;
}
[data-direction = rtl] .address  {
    text-align: right !important;
}


.account-address .address button {
  border: none;
  color: #FFF;
  font-size: 18px;
  background-color: #B2CC9B;
  margin: 0.8rem 0.5rem;
  border-radius: 10px;
  font-weight: bold;
  padding: .5rem 2rem;
}

/* Cards Buttons*/
.account-address .actions button {
  border: none;
  color: #FFF;
  font-size: 18px;
  background-color: #B2CC9B;
  margin: 0.8rem 0.5rem;
  border-radius: 9px;
  font-weight: bold;
  width: 94px;
  padding: .5rem 0.5rem;
}

.account-address .actions .delete-button {
  color: #6F825F;
  background-color: #F7F6E2;
}

.account-address .actions .edit-button {
  color: #FFF;
  background-color: #B2CC9B;
}

/* Cards */
.account-address .cards {
  margin-top: 2rem;
}

.account-address .cards .card {
  border: 1px solid #B2CC9B;
  padding: 2rem;
  height: 90%;
  margin-bottom: .3rem;
}

.account-address .cards .card .heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
}

.account-address .cards .card .heading h4 {
  font-size: 18px;
  font-weight: bold;
  color: #6F825F;
  margin-bottom: 0;
}

.account-address .cards .card .heading input {
  accent-color: #B2CC9B;
}

.account-address .cards .card span,
.account-address .cards .card p {
  color: #444444;
  font-size: 14px;
  margin-bottom: .5rem;
}

.account-address .cards .card p {
  margin-bottom: 0;
}

.account-address .background h3 {
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}


/* Address */
.account-address .address h3 {
  font-size: 22px;
  font-weight: bold;
  color: #1E181A;
  margin-bottom: 1rem;
}

/* No Data */
.account-address .address .no-data {
  background-color: #F8F8F8;
  border-radius: 10px;
  width: 29rem;
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.account-address .address .no-data img {
  width: 4.5rem;
  margin-right: 2rem;
}

.account-address .address .no-data p {
  font-size: 18px;
  color: black;
  margin-bottom: 0;
}
</style>
