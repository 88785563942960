<template>
  <div class="account-edit" id="account-edit">
    <h2>{{ $t('Edit Account') }}</h2>
    <form @submit.prevent="updateUserInfo">
      <label>{{ $t('Full Name') }}*</label>
      <input type="text" v-model="user.name" :placeholder="$t('Enter Here')" required/>
      <label>{{ $t('Email') }}*</label>
      <input type="email" v-model="user.email" :placeholder="$t('Enter Here')" required/>
      <label>{{ $t('Mobile Number') }}*</label>
      <input type="text" v-model="user.phone" pattern="[0-9]{8}" :placeholder="$t('Enter Here')" required/>

      <input type="submit" value="Update" :disabled="!!isLoading">
    </form>
    <div class="error-message">
      <p v-for="error in errorMessages" :key="error">{{ error }}</p>
    </div>
    <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal" style="display: none;"></button>
    <MessageModal :content="modalMessage"/>
  </div>
</template>


<script>
import store from '@/store'
import MessageModal from '@/components/global/MessageModal.vue'

export default {
  name: "EditUserInfo",
  data() {
    return {
      user: {
        name: '',
        email: '',
        phone: ''
      },
      modalMessage: '',
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getUserInfo() {
      fetch(this.serverURL + 'api/auth/my-account', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            if (data.status == 200) {
              this.user.name = data.data.name;
              this.user.email = data.data.email;
              this.user.phone = data.data.phone;
            }
          })
          .catch(error => {
            console.log(error);
          })
    },
    updateUserInfo() {
      fetch(this.serverURL + 'api/auth/my-account', {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(this.user)
      })
          .then(response => response.json())
          .then(data => {
            if (data.status == 200) {
              this.errorMessages = [];
              this.modalMessage = 'User information updated successfully';
              localStorage.setItem('user_info', JSON.stringify(data.data));
              store.state.userInfo = JSON.parse(localStorage.getItem('user_info'));
              this.$nextTick(() => {
                document.getElementById('openModal').click();
              });
            } else {
              let messages = [];

              for (let propt in data.errors) {
                messages.push(data.errors[propt].join(' '));
              }
              this.errorMessages = messages;
            }
          })
          .catch(error => {
            console.log(error.message);
          })
    }
  },
  beforeMount() {
    this.getUserInfo();
  },
  mounted() {
  },
  components: {
    MessageModal,
  },
}
</script>

<style>
.account-edit {
  text-align: left;
}

.account-edit h2 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
}

/* Form */
.account-edit form label {
  display: block;
  font-size: 14px;
  color: #6F825F;
  margin: 2rem 0 1rem;
}

.account-edit form input {
  border-radius: 10px;
  background-color: #F5F5F5;
  padding: 1rem 2rem;
  font-size: 14px;
  color: black;
  width: 30rem;
}

@media (max-width: 500px) {
  .account-edit form input {
    width: 100%;
  }
}

.account-edit form input::placeholder {
  color: #A2A2A2;
}

.account-edit form input[type='email'] {
  background-color: #9B9A9A;
  color: #FFF;
}

.account-edit form input[type='email']::placeholder {
  color: #FFF;
}

.account-edit form input[type='submit'] {
  margin-top: 2rem;
  background-color: #B2CC9B;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
}
</style>