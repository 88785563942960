<template>
  <div class="order-details" id="order-details">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{$t('ORDER DETAILS')}}</h3>
    </div>
    <div class="container">
      <router-link class="back" to="/MyAccount">
        <v-icon class="back-arrow" icon="mdi-arrow-left" />
        <span>{{ $t('Back To Orders') }}</span>
      </router-link>
      <div class="row">
        <div class="col-md-8">
          <div class="orders">
            <div class="info">
              <div class="row">
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Order Date') }}</span>
                  <span class="value">{{ info.created_at?info.created_at.substring(0, 10):'' }}</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Order Id') }}</span>
                  <span class="value">{{ info.id }}</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t("Order Status") }}</span>
                  <span class="value">{{ info.status }}</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Order Total') }}</span>
                  <span class="value">{{ info.total_amount }} KD</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Payment Method') }}</span>
                  <span class="value">{{ info.payment_method }}</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Transaction ID') }}</span>
                  <span class="value">{{ info.payment_id?info.payment_id:'-' }}</span>
                </div>
                <div class="item col-xl-3 col-lg-4 col-6">
                  <span class="key">{{ $t('Invoice Reference') }}</span>
                  <span class="value">{{ info.invoice_id }}</span>
                </div>
              </div>
            </div>
            <div class="summary">
              <h2>{{$t('Order Summary')}}</h2>
              <div class="cards">
                <div class="card" v-for="product in info.products" :key="product.id" :data-id="product.id">
                  <div class="row">
                    <div class=col-6>
                      <div class="product">
                        <img :src="product.image" alt="Product Image">
                        <div class="details">
                          <p>{{ product.name }}</p>
                          <span v-if="product.product_color_size.size">Size: {{ product.product_color_size.size.name }}</span>
                          <span v-if="product.product_color_size.color">Color: {{ product.product_color_size.color.name }}</span>
                        </div>
                      </div>
                    </div>
                    <div class=col-3>
                      <div class="quantity">{{ $t('Qty') }}: {{ product.quantity }}</div>
                    </div>
                    <div class=col-3>
                      <div class="price">{{ product.quantity * product.price }} {{ $t('KD') }}</div>
                      <div v-if="product.status" class="state">
                        <div class="message">{{ product.status.split('_').join(' ') }}</div>
                        <button v-if="product.status == 'REQUESTED_TO_EXCHANGE'" data-bs-toggle="modal" data-bs-target="#RequestModal">View Request</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="payment">
            <div class="address">
              <h3>{{ $t('Delivery Address') }}</h3>
              <h5>{{ info.address?info.address.title:'' }}</h5>
              <span>{{ info.address?info.address.area.name:'' }}</span>
              <p v-if="info.address">Block {{ info.address.block }}, Street {{ info.address.street }}, Avenue {{ info.address.avenue }}.</p>
            </div>
            <div class="amount">
              <div class="item">
                <span>{{ $t('Products Total') }}</span>
                <span>{{ info.total_products }} {{ $t('KD') }}</span>
              </div>
              <div class="item">
                <span>{{ $t('Discount') }}</span>
                <span>{{ info.discount }} {{ $t('KD') }}</span>
              </div>
              <div class="item">
                <span>{{ $t('Delivery Charges') }}</span>
                <span>{{ info.deliver_charge }} {{ $t('KD') }}</span>
              </div>
              <div class="item total">
                <span>{{ $t('Total') }}:</span>
                <span>{{ info.total_amount }} {{ $t('KD') }}</span>
              </div>
            </div>
            <a class="cancel" v-if="info.status == 'PLACED'" data-bs-toggle="modal" data-bs-target="#cancelOrderModal">{{$t('Cancel Order')}}</a>
            <a class="reorder" v-if="info.status == 'DELIVERED'" data-bs-toggle="modal" data-bs-target="#ReorderModal">{{ $t('Reorder') }}</a>
            <a class="refund" v-if="can_return && info.status == 'DELIVERED'" data-bs-toggle="modal" data-bs-target="#RefundModal">{{ $t('Return & Refund') }}</a>
            <a class="exchange" v-if="can_return && info.status == 'DELIVERED'" data-bs-toggle="modal" data-bs-target="#ExchangeModal">{{ $t('Return & Exchange') }}</a>
            <div class="error-message">
              {{ errorMessage }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <cancel-order-modal />
    <reorder-modal />
    <refund-modal :order_id="id" :products="info.products?info.products:[]" />
    <exchange-modal :order_id="id" :products="info.products?info.products:[]" />
    <request-modal />
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import CancelOrderModal from '@/components/Order/CancelOrderModal.vue'
import ReorderModal from '@/components/Order/ReorderModal.vue'
import RefundModal from '@/components/Order/RefundModal.vue'
import ExchangeModal from '@/components/Order/ExchangeModal.vue'
import RequestModal from '@/components/Order/RequestModal.vue'

export default {
  name: "OrderDetailsView",
  props: {
    id: String
  },
  data() {
    return {
      info: {},
      can_return: false,
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/orders/' + this.id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200){
            this.info = data.data;
            for(let i = 0; i < this.info.products.length; i++){
              if(this.info.products[i].status == null){
                this.can_return = true;
                break;
              }
            }
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    cancelOrder() {
      fetch(this.serverURL + 'api/catalog/orders/' + this.id + '/cancel', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200){
            this.$router.push({ name: 'MyAccount' });
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    reorderOrder() {
      fetch(this.serverURL + 'api/catalog/orders/' + this.id + '/reorder', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 201){
            window.open(data.data.payment_url);
          }else if(data.status == 400){
            this.errorMessage = 'One Or More Products Are Not Available For Reorder';
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
  beforeMount() {
    this.getInfo();
  },
  mounted() {
    let that = this;

    // On Yes Clicked
    window.$('.order-details .modal .yes-btn').on('click', function() {
      that.cancelOrder();
    });

    // On Reorder Clicked
    window.$('.order-details .modal .reorder-btn').on('click', function() {
      that.reorderOrder();
    });

    // On Reorder Clicked
    window.$('.order-details .modal .ok-btn').on('click', function() {
      location.reload();
    });

    // On Toggle Refund Modal
    window.$( '#RefundModal,#ExchangeModal')
      .on('hide.bs.modal', function() {
        window.$('html').css('overflow', 'initial');
      })
      .on('show.bs.modal', function() {
        window.$('html').css('overflow', 'hidden');
      });
  },
  components: {
    Loading,
    CancelOrderModal,
    ReorderModal,
    RefundModal,
    ExchangeModal,
    RequestModal,
  },
};
</script>

<style>
/* Background */
.order-details .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.order-details .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.order-details .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.order-details .container{
  padding-top: 2rem;
  padding-bottom: 2rem;
  text-align: left;
}
/* Back Button */
.order-details .container .back{
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  color: #6F825F;
}
.order-details .container .back .v-icon{
  color: #FFF;
  background-color: #6F825F;
  border-radius: 50%;
  margin-right: .5rem;
}

/* Orders */
.order-details .orders{
  margin-top: 2rem;
}
.order-details .orders .info{
  background-color: #B2CC9B;
  border-radius: 10px;
  color: #FFF;
  padding: 1.5rem 1.5rem .5rem;
}
.order-details .orders .info .item{
  margin-bottom: 1rem;
  line-height: 2;
}
.order-details .orders .info .item .key,
.order-details .orders .info .item .value{
  display: block;
  font-size: 16px;
}
.order-details .orders .info .item .value{
  font-weight: bold;
}

.order-details .orders .summary{
  margin: 2rem 0;
}
.order-details .orders .summary h2{
  font-size: 22px;
  font-weight: bold;
  color: #1E181A;
}
.order-details .orders .summary .cards .card{
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9B9A9A;
}
.order-details .orders .summary .cards .card .product{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.order-details .orders .summary .cards .card .product img{
  width: 5rem;
  margin-right: .5rem
}
.order-details .orders .summary .cards .card .product .details p{
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: .5rem;
}
.order-details .orders .summary .cards .card .product .details span{
  color: #B2CC9B;
  font-size: 14px;
  display: block;
}
.order-details .orders .summary .cards .card .product .details span:first-of-type{
  margin-bottom: .5rem;
}
.order-details .orders .summary .cards .card .quantity{
  font-size: 20px;
  font-weight: bold;
  color: #6F825F;
}
.order-details .orders .summary .cards .card .price,
.order-details .orders .summary .cards .card .message{
  text-align: right;
}
.order-details .orders .summary .cards .card .price{
  font-size: 18px;
  color: #000;
}
.order-details .orders .summary .cards .card .state{
  margin-top: .5rem;
}
.order-details .orders .summary .cards .card .state button,
.order-details .orders .summary .cards .card .state .message{
  padding: .3rem .7rem;
  font-size: 16px;
  border-radius: 10px;
  color: #FFF;
  display: block;
  width: fit-content;
  text-align: center;
  margin: auto;
}
.order-details .orders .summary .cards .card .state .message{
  background-color: #CC9B9B;
}
.order-details .orders .summary .cards .card .state button{
  background-color: #B2CC9B;
  margin-top: .3rem;
}

/* Payment */
.order-details .payment .address{
  margin-top: 2rem;
  background-color: #6F825F;
  border-radius: 10px;
  padding: 1.5rem;
  color: #FFF;
}
.order-details .payment .address h3{
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2rem;
}
.order-details .payment .address h5{
  font-size: 18px;
  font-weight: bold; 
}
.order-details .payment .address span,
.order-details .payment .address p{
  font-size: 14px;
}
.order-details .payment .address p{
  margin-bottom: 0;
  margin-top: .5rem;
}

.order-details .payment .amount{
  background-color: #F7F6E2;
  border-radius: 10px;
  padding: 2rem;
  margin-top: 1rem;
}
.order-details .payment .amount span{
  color: #6F825F;
  font-size: 18px;
}
.order-details .payment .amount .total span{
  font-weight: bold;
}
.order-details .payment .amount .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.order-details .payment a{
  margin-top: 1rem;
  cursor: pointer;
  text-decoration: none;
  background-color: #B2CC9B;
  border-radius: 10px;
  color: #FFF;
  width: 100%;
  display: block;
  padding: .8rem .5rem;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.order-details .payment .cancel,
.order-details .payment .reorder{
  background-color: #B2CC9B;
  color: #FFF;
}
.order-details .payment .refund,
.order-details .payment .exchange{
  background-color: #EAF1E4;
  color: #6F825F;
}
</style>