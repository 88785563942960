<template>
  <div class="home-best" id="home-best">
    <div class="container">
      <h2>{{ $t('Best Selling') }}Best Selling</h2>
      <p v-html="text?.content"></p>
      <div v-if="products.length > 0">
        <div v-if="website" class="cards">
          <div class=row>
            <div class="col-sm-6 col-md-4 col-lg-3" v-for="product in products" :key="product.id">
              <div class="card" :data-id="product.id" :data-name="product.name">
                <div class="product-image">
                    <img :src="product.image" alt="Product Image" />
                </div>
                <h5>{{ product?.translations[0]?.locale.includes(language)?product?.translations[0]?.name:product?.translations[1]?.locale.includes(language)?product?.translations[1]?.name:product?.name  }}</h5>
                <div class="prefix">
                  <span>{{ product.offer_price && handleDate(product?.offer_price_end)?product.offer_price:product.price }} {{ $t('KD') }}</span>
                  <img v-if="product.is_added_to_wishlist" src="@/assets/icons/red-heart.svg" data-value="remove" alt="Heart Icon" />
                  <img v-else src="@/assets/icons/black-heart.svg" data-value="add" alt="Heart Icon" />
                </div>
                <span v-if="product.offer_price && handleDate(product?.offer_price_end)" class="old-price">{{ product.offer_price?product.price + ' KD':'' }}</span>
              </div>
            </div>
          </div>
        </div>
        <Carousel v-else class="cards" :breakpoints="breakpoints" :wrap-around="wrapAround">
          <Slide v-for="product in products" :key="product.id">
            <div class="carousel__item">
              <div class="card" :data-id="product.id" :data-name="product.name">
                <div class="product-image">
                    <img :src="product.image" alt="Product Image" />
                </div>
                <h5>{{ product?.translations[0]?.locale.includes(language)?product?.translations[0]?.name:product?.translations[1]?.locale.includes(language)?product?.translations[1]?.name:product?.name  }}</h5>
                <div class="prefix">
                  <span>{{ product.offer_price && handleDate(product?.offer_price_end)?product.offer_price:product.price }} {{ $t('KD') }}</span>
                  <img v-if="product.is_added_to_wishlist" src="@/assets/icons/red-heart.svg" data-value="remove" alt="Heart Icon" />
                  <img v-else src="@/assets/icons/black-heart.svg" data-value="add" alt="Heart Icon" />
                </div>
                <span v-if=" product.offer_price && handleDate(product?.offer_price_end)" class="old-price">{{ product.offer_price?product.price + ' KD':'' }}</span>
              </div>
            </div>
          </Slide>
          <template #addons>
            <Pagination />
          </template>
        </Carousel>   
        <router-link class="explore" to="/Products/Best-Selling">{{$t('Explore More')}}</router-link>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon">
        <h3>No Products Found</h3>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Pagination } from 'vue3-carousel'
import langStor from '@/langStore'

import store from '@/store'


export default {
  name: "HomeBestSellingSection",
  props:['home'],
  data() {
    return {
      products: [],
      website: true,
      breakpoints: {
        // 0 and up
        0: {
          itemsToShow: 2,
          snapAlign: 'start',
        },
      },
      wrapAround: true,
    }
  },
  methods: {
    handleDate(value){
      const dateToCompare = new Date(value);

          // Get today's date
          const today = new Date();

          // Set the time to 00:00:00 for both dates to only compare the date part
          today.setHours(0, 0, 0, 0);
          dateToCompare.setHours(0, 0, 0, 0);

          // Compare dates
          if (dateToCompare < today) {
              return false
          } else {
            return true
      }
    },
    getProducts() {
      fetch(this.serverURL + 'api/catalog/products?filter[best_selling]=1&items_per_page=8', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            this.products = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addWishList(id) {
      console.log('>>>>>>>>>>>>>>',this.token);
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
        console.log('>>>>>>>>>>>>>>',this.token);

    },
    removeWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getProducts();
  },
  mounted() {
    console.log(store.state.accessToken);
    let that = this;

    // Init Window Width
    if (window.innerWidth < 576){
      that.website = false;
    }else{
      that.website = true;
    }

    // On Resize Body
    window.$(window).on('resize', function(e){
      if (e.target.innerWidth < 576){
        that.website = false;
      }else{
        that.website = true;
      }
    });

    // Card Clicked
		window.$('body').on('click', '.home-best .cards .card', function (){
      localStorage.setItem('product_id', window.$(this).data('id'));

      let fullPath = '/Product/' + window.$(this).data('id');
      that.$router.push({ path: fullPath });
    });

    // Heart Clicked
    window.$('body').on('click', '.home-best .cards .card .prefix img', function (e){
      e.stopImmediatePropagation();
      if(localStorage.getItem('token')=='' || !localStorage.getItem('token')){
        that.$router.push({ name: 'Login' });
      }
      else{
        let id = window.$(this).parents('.card').data('id');

        if(window.$(this).data('value') == 'add'){
          that.addWishList(id);
        }else{
          that.removeWishList(id);
        }
      }
    });
  },
  computed: {
    text(){
      return this.home?.translations ? this.home.translations.filter(item=>{
        return item.name == 'best_sellings' && item.locale == langStor.state.language
      })[0] : '';
    },
    token () {
      return store.state.accessToken;
    },
    language(){
      return langStor.state.language
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
  },
};
</script>

<style>
.product-image{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
}
.home-best {
  padding: 2rem 0;
  background-color: #FBFBFB;
}
.home-best h2 {
  font-size: 42px;
  font-weight: bold;
  color: black;
  margin-bottom: 1rem;
}
.home-best p {
  font-size: 16px;
  color: #4B4B4B;
  width: 40%;
  margin: 0 auto 2rem;
}
/* cards */
.home-best .cards{
  padding: 1rem 0;
  text-align: left;
}
.home-best .cards .row > div{
  margin-bottom: 1rem;
}
.home-best .cards .card,
.home-best .cards .card > img{
  border-radius: 10px;
}
.home-best .cards .card{
  padding: .8rem;
  height: 100%;
  box-shadow: 6px 10px 25px 0 rgba(0, 0, 0, .1);
  border: none;
  cursor: pointer;
}
.home-best .cards .card > img{
  width: 100%;
}
.home-best .cards .card h5{
  color: black;
  font-size: 18px;
  margin: .8rem 0;
}
.home-best .cards .card .prefix{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-best .cards .card .prefix span{
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.home-best .cards .card .prefix img{
  background-color: #F7F6E2;
  padding: .5rem;
  border-radius: 10px;
}
.home-best .cards .card .old-price{
  color: #A2A2A2;
  font-size: 16px;
  text-decoration: line-through;
  text-align: left;
}
.home-best .explore{
  text-decoration: none;
  color: #FFF;
  border-radius: 15px;
  background-color: #B2CC9B;
  padding: .5rem 0;
  max-width: 12rem;
  display: block;
  margin: 2rem auto 1rem;
  text-align: center;
  font-size: 22px;
}
@media(max-width: 992px){
	.home-best p{
		width: 60%;
	}
}
@media(max-width: 767px){
	.home-best p{
		width: 80%;
	}
}
@media(max-width: 576px){
  .home-best .carousel__viewport{
    padding: 1rem 0;
  }
  .home-best .cards{
    padding: 0;
  }
  .home-best .cards .card{
    margin: 0 .5rem;
    box-shadow: 0px 0px 15px 0 rgba(0, 0, 0, .1);
  }
  .home-best .explore {
    margin: 2rem auto 0;
  }
}
@media(max-width: 500px){
  .home-best h2{
    font-size: 35px;
  }
  .home-best p{
		width: 90%;
	}
  .home-best .cards .card h5{
    font-size: 14px;
  }
  .home-best .cards .card .prefix span{
    font-size: 16px;
  }
  .home-best .cards .card .old-price{
    font-size: 12px;
  }
}
@media(max-width: 400px){
  .home-best h2{
    font-size: 30px;
  }
	.home-best p{
		width: 100%;
	}
  .home-best .cards .card h5{
    font-size: 12px;
  }
  .home-best .cards .card .prefix span{
    font-size: 14px;
  }
  .home-best .cards .card .prefix img{
    width: 1.5rem;
    padding: .3rem;
  }
  .home-best .cards .card .old-price{
    font-size: 10px;
  }
  .home-best .explore{
    max-width: 9rem;
    font-size: 15px;
  }
}
@media(max-width: 300px){
  .home-best h2{
    font-size: 22px;
  }
	.home-best p{
		width: 100%;
    font-size: 12px;
	}
  .home-best .cards .card h5{
    font-size: 10px;
  }
  .home-best .cards .card .prefix span{
    font-size: 12px;
  }
  .home-best .cards .card .old-price{
    font-size: 8px;
  }
}

/* No Data */
.home-best .container .no-data{
  width: 29rem;
  margin: auto;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .home-best .container .no-data{
    width: 90%;
  }
}
.home-best .container .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
</style>