<template>
  <div class="refund-modal" id="refund-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="RefundModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Return & Refund</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <h3>Select Items For Refund</h3>
            <div class="items">
              <div class="item" v-for="product in products" :key="product.id" :data-id="product.id">
                <div v-if="product.status == null">
                  <div class="product">
                    <img :src="product.image" alt="Product Image">
                    <div class="details">
                      <p>{{ product.name }}</p>
                      <span v-if="product.product_color_size.size">Size: {{ product.product_color_size.size.name }}</span>
                      <span v-if="product.product_color_size.color">Color: {{ product.product_color_size.color.name }}</span>
                    </div>
                  </div>
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div class="reason">
              <h3>Enter Reason Why You Want To Refund</h3>
              <textarea v-model="reason" rows="5" placeholder="Enter Here"></textarea>
              <p>By Continuing up you accept all terms & conditions of <span>Tiny Cola</span>!</p>
            </div>
            <button
              class="refund-btn"
              @click="sendData"
            >Return & Refund</button>
            <div class="error-message">
              {{ errorMessage }}
            </div>
            <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal" style="display: none;"></button>
          </div>
        </div>
      </div>
    </div>

    <message-modal content="Request sent successfully.Our team will review it soon!"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'

import store from '@/store'

export default {
  name: "RefundView",
  props: ['order_id', 'products'],
  data() {
    return {
      reason: '',
      carts: [],
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
    sendData(){
      if(this.carts.length == 0 || this.reason == ''){
        this.errorMessage = 'You Must Fill Inforamtion First.';
      }else{
        this.errorMessage = '';

        let obj = {
          order_id: this.order_id,
          reason: this.reason,
          items: []
        };
        for(let i = 0; i < this.carts.length; i++){
          obj.items.push({
            order_product_id: this.carts[i],
          });
        }

        fetch(this.serverURL + 'api/refund-requests', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: JSON.stringify(obj)
        })
          .then(response => response.json())
          .then(data => {
            if(data.status == 201){
              window.$('.refund-modal #openModal').click();
            }
          })
          .catch(error => {
            console.log(error);
          })
      }
    },
  },
  mounted() {
    let that = this;

    // Input Checked
    window.$('body').on('change', '.refund-modal .modal .modal-body .items .item input[type="checkbox"]', function (){
      let id = window.$(this).parents('.item').data('id');

      if (this.checked){
        that.carts.push(id);
      }else{
        let index = that.carts.indexOf(id);
        that.carts.splice(index, 1);
      }
    });
  },
  components: {
    MessageModal,
  },
};
</script>

<style>
.refund-modal .modal{
  background-color: #E7E7E7;
}
.refund-modal .modal .modal-content {
  border-radius: 10px;
  padding: 2rem;
  border: none;
}
.refund-modal .modal .modal-header{
  border-bottom: none;
  padding: 0;
}
.refund-modal .modal .modal-title {
  color: #6F825F;
  font-size: 18px;
  font-weight: bold;
}
/* Body */
.refund-modal .modal .modal-body{
  text-align: left;
  color: #1E181A;
  font-size: 15px;
  padding: 0;
}
.refund-modal .modal .modal-body > h3{
  font-size: 16px;
  color: #1E181A;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
/* Items */
.refund-modal .modal .modal-body .items .item{
  border-radius: 0;
  border: none;
}
.refund-modal .modal .modal-body .items .item > div{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.refund-modal .modal .modal-body .items .item:not(:first-of-type){
  margin-top: 2rem;
}

.refund-modal .modal .modal-body .items .item .product{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.refund-modal .modal .modal-body .items .item .product img{
  width: 5rem;
  margin-right: .5rem
}
.refund-modal .modal .modal-body .items .item .product .details p{
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: .5rem;
}
.refund-modal .modal .modal-body .items .item .product .details span{
  color: #B2CC9B;
  font-size: 14px;
  display: block;
}
.refund-modal .modal .modal-body .items .item .product .details span:first-of-type{
  margin-bottom: .5rem;
}
.refund-modal .modal .modal-body .items .item input[type='checkbox']{
  accent-color: #B2CC9B;
}

/* Reason */
.refund-modal .modal .modal-body .reason{
  margin-top: 1rem;
}
.refund-modal .modal .modal-body .reason h3{
  color: #6F825F;
  font-size: 14px;
  margin-bottom: 1rem;
}
.refund-modal .modal .modal-body .reason textarea{
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 1rem;
  color: #A2A2A2;
  font-size: 14px;
}
.refund-modal .modal .modal-body .reason p{
  color: #6F825F;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: .5rem;
}
.refund-modal .modal .modal-body .reason p span{
  font-weight: bold;
}
.refund-modal .modal .refund-btn{
  border: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  padding: .7rem 2rem;
  color: #FFF;
  background-color: #B2CC9B;
  margin: 1rem auto 0;
  display: block;
}
</style>
