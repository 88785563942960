<template>
  <div class="reset-password" id="reset-password">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Reset Password') }}</h3>
    </div>
    <div class="container">
      <div class="form">
        <img src="@/assets/icons/resetPassword/syn-lock.svg" alt="Reset Password Icon">
        <h1>{{ $t('Reset') }}<br />{{ $t('Your Password') }}</h1>
        <form>
          <label>{{ $t('New Password') }}*</label>
          <input type="password" v-model="data.password" placeholder="Enter Here" required>
          <label>{{ $t('Confirm New Password') }}*</label>
          <input type="password" v-model="data.password_confirmation" placeholder="Enter Here" required>

          <input class="submit-btn" type="submit" :value="$t('Reset')" :disabled="isLoading ? true:false">
          <p class="error-message">{{ errorMessage }}</p>
        </form>
        <button data-bs-toggle="modal" data-bs-target="#messageModal">{{ $t('Test') }}</button>
      </div>
    </div>

    <message-modal content="Your Password has been reset successfully !"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'

export default {
  name: "ResetPasswordView",
  data() {
    return {
      data: {
        password: '',
        password_confirmation: '',
      },
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
  },
  beforeMount() {},
  mounted() {},
  components: {
    MessageModal,
  },
};
</script>

<style>
/* Background */
.reset-password .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.reset-password .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.reset-password .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* Form */
.reset-password .form{
  padding: 4rem 0 2rem;
  width: 50%;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 992px){
  .reset-password .form{
    width: 60%;
  }
}
@media (max-width: 767px){
  .reset-password .form{
    width: 70%;
  }
}
@media (max-width: 400px){
  .reset-password .form{
    width: 80%;
  }
}
@media (max-width: 300px){
  .reset-password .form{
    width: 90%;
  }
}
.reset-password .form h1{
  font-size: 32px;
  font-weight: bold;
  color: #6F825F;
  margin: 2rem 0;
}
.reset-password .form label{
  color: #6F825F;
  font-size: 14px;
  display: block;
  margin: .5rem 0;
}
.reset-password .form label:not(:first-of-type){
  margin-top: 2rem;
}
.reset-password .form input{
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  outline: none;
  padding: .8rem 2rem;
  border-radius: 10px;
}
.reset-password .form input::placeholder{
  color: #A2A2A2;
  font-size: 14px;
  font-weight: lighter;
}
.reset-password .form .forgot-password{
  display: block;
  text-decoration: none;
  color: black;
  font-size: 14px;
  text-align: right;
  margin: 1rem 0 0 0;
}
.reset-password .form .submit-btn{
  background-color: #B2CC9B;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
}
.reset-password .form .signup-text{
  color: black;
  font-size: 16px;
  margin-top: 2rem;
  text-align: center;
}
.reset-password .form .signup-btn{
  display: block;
  font-size: 18px;
  font-weight: bold;
  background-color: #EAF1E4;
  padding: .8rem 2rem;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: #6F825F;
}
</style>