<template>
  <div class="cancel-order-modal" id="cancel-order-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="cancelOrderModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Cancel Order</h5>
          </div>
          <div class="modal-body">
            Are you sure want to Cancel Order?
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="no-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >No</button>
            <button
              type="button"
              class="yes-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
            >Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "CancelOrderView",
  data() {
    return {
    }
  },
  methods: {
  },
  watch:{
  },
  computed: {
  },
};
</script>

<style>
.cancel-order-modal .modal{
  background-color: #E7E7E7;
  padding-top: 30vh;
}
.cancel-order-modal .modal .modal-content {
  border-radius: 10px;
  padding: 2rem 1.5rem;
  border: none;
}
.cancel-order-modal .modal .modal-header{
  border-bottom: none;
  padding: 0;
}
.cancel-order-modal .modal .modal-title {
  color: #6F825F;
  font-size: 18px;
  font-weight: bold;
}
.cancel-order-modal .modal .modal-body{
  text-align: left;
  margin-top: .5rem;
  color: #1E181A;
  font-size: 15px;
  padding: 0;
}
.cancel-order-modal .modal .modal-footer{
  padding: 0;
  border-top: 0;
  margin-top: 1rem;
}
.cancel-order-modal .modal .yes-btn,
.cancel-order-modal .modal .no-btn{
  border: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  padding: .7rem 2rem;
}
.cancel-order-modal .modal .yes-btn{
  color: #FFF;
  background-color: #B2CC9B;
}
.cancel-order-modal .modal .no-btn{
  color: #000;
  background-color: transparent;
}
</style>
