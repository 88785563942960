<template>
  <div class="return-policy" id="return-policy">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('RETURN POLICY') }}</h3>
    </div>
    <div class="container">
      <div class="content">
        <h1 v-html="displayName">
          
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import langStor from '@/langStore'

import store from '@/store'
export default {
  name: "TermsView",
  data(){
    return{
      info:{}
    }
  },
  methods: {
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/cms/pages/return-policy', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200){
            this.info = data.data;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
  beforeMount() {
    this.getInfo()
  },
  mounted() {
  },
  components: {},
  computed: {
    language(){
      return langStor.state.language
    },
    displayName() {
      if (!this.info?.translations || this.info.translations.length === 0) {
        return this.info?.content || '';
      }
      
      const translation = this.info.translations.find(t => t.locale.includes(this.language));
      return translation ? translation.content : this.info?.content || '';
    },
  },
};
</script>
<style scoped>
.return-policy .background {
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}

.return-policy .background .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}

.return-policy .background h3 {
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.return-policy .container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}


.return-policy .content {
  text-align: left;
}

.return-policy .content h1 {
  font-size: 16px;
  color: #333;
  line-height: 2;
}
</style>
