<template>
  <div class="category" id="category">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ computedCategoryName }}</h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <div class="filter">
            <div class="sub-category">
              <div class="title">
                <img src="@/assets/icons/sticker.svg" alt="Sticker Icon">
                <h3>{{ $t('Sub Category') }}</h3>
              </div>
              <div v-if="subCategories.length > 0" class="items">
                <div class="item" v-for="subCategory in subCategories" :key="subCategory.id">
                  <label>{{ subCategory.translations.find(t => t.locale.includes(language))?.name }}</label>
                  <input type="checkbox" :data-id="subCategory.id"/>
                </div>
              </div>
              <div v-else class="no-subcategory">
                {{ $t('No Subcategory') }}.
              </div>
            </div>
            <div class="price">
              <div class="title">
                <img src="@/assets/icons/sticker.svg" alt="Sticker Icon">
                <h3>{{ $t('PRICE') }}</h3>
              </div>
              <div class="value">
                <p><span class="min-val">{{ filter.price[0] }}</span> {{ $t('to') }} <span class="max-val">{{ filter.price[1] }}</span> {{ $t('KWD') }}</p>
                <v-card>
                  <v-card-text>
                    <v-range-slider
                      :min="minPrice"
                      :max="maxPrice"
                      :step="1"
                      v-model="filter.price"
                      strict
                      hide-details
                      @end="getProducts"
                    ></v-range-slider>
                  </v-card-text>
                </v-card>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-9">
          <div class="content">
            <div class="details">
              <span>{{ products.length }} {{ $t('Products') }}.</span>
              <div class="sortby">
                <span>{{ $t('SORT BY') }}</span>
                <select class="form-select" v-model="filter.sorted">
                  <option value="-name">{{$t('A-Z')}}</option>
                  <option value="+name">{{ $t('Z-A') }}</option>
                  <option value="-created_at">{{ $t('New In') }}</option>
                  <option value="-price">{{ $t('Low-High') }}</option>
                  <option value="+price">{{ $t('High-Low') }}</option>
                </select>
              </div>
            </div>
            <div v-if="products.length > 0" class="cards">
              <div class=row>
                <div class="col-sm-6 col-md-4 col-lg-3" v-for="product in products" :key="product.id">
                  <div class="card" :data-id="product.id" :data-name="product.name">
                    <div class="product-image">
                        <img :src="product.image" alt="Product Image" />
                    </div>
                    <div class="card-body d-flex flex-column m-0 p-0">
                      <h5 class="pro_title">{{ product?.translations[0]?.locale.includes(language)?product?.translations[0]?.name:product?.translations[1]?.locale.includes(language)?product?.translations[1]?.name:product?.name  }}</h5>
                      <div class="d-flex flex-column justify-content-between">
                        <div class="prefix">
                          <span>{{ product.offer_price?product.offer_price:product.price }} {{ $t('KD') }}</span>
                          <img v-if="product.is_added_to_wishlist" src="@/assets/icons/red-heart.svg" data-value="remove" alt="Heart Icon" />
                          <img v-else src="@/assets/icons/black-heart.svg" data-value="add" alt="Heart Icon" />
                        </div>
                        <span :class="{visability:!handleDate(product.offer_price_end)} || product.offer_price==null || !product.offer_price" class="old-price">{{ product.offer_price?product.price + ' KD':'200' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="no-data">
              <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon">
              <h3>{{ $t('No Products Found') }}</h3>
              <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import langStor from '@/langStore'

import store from '@/store'

export default {
  name: "CategoryView",
  props: {
    categoryName: String
  },
  data() {
    return {
      filter: {
        price: [0, 1000],
        sorted: '',
        subCategory: [],
      },
      minPrice: 0,
      maxPrice: 1000,
      subCategories: [],
      products: [],
      isLoading: false
    }
  },
  methods: {
    handleDate(value){
      const dateToCompare = new Date(value);

          // Get today's date
          const today = new Date();

          // Set the time to 00:00:00 for both dates to only compare the date part
          today.setHours(0, 0, 0, 0);
          dateToCompare.setHours(0, 0, 0, 0);

          // Compare dates
          if (dateToCompare < today) {
              return false
          } else {
            return true
      }
    },
    getSubcategories() {
      fetch(this.serverURL + 'api/catalog/categories/' + localStorage.getItem('category_id') + '/sub-categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            this.subCategories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getProducts() {
      this.isLoading = true;
      let path = '?filter[categories]=';

      if(this.filter.subCategory.length > 0)
        path += this.filter.subCategory.join(',');
      else
        path += localStorage.getItem('category_id');

      if(this.filter.sorted != '')
        path += '&sort=' + this.filter.sorted;

      path += '&filter[price_from]=' + this.filter.price[0];
      path += '&filter[price_to]=' + this.filter.price[1];

      fetch(this.serverURL + 'api/catalog/products' + path, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200)
            this.products = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    addWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    },
    removeWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {
    this.getSubcategories();
    this.getProducts();
  },
  mounted() {
    let that = this;

    // Card Clicked
    window.$('body').on('click', '.category .cards .card', function (){      
      localStorage.setItem('product_id', window.$(this).data('id'));

      let fullPath = '/Product/' + window.$(this).data('id');
      that.$router.push({ path: fullPath });
    });

    // Heart Clicked
    window.$('body').on('click', '.category .cards .card .prefix img', function (e){
      e.stopImmediatePropagation();
      if(localStorage.getItem('token')=='' || !localStorage.getItem('token')){
        that.$router.push({ name: 'Login' });
      }
      else{
        let id = window.$(this).parents('.card').data('id');

        if(window.$(this).data('value') == 'add'){
          that.addWishList(id);
        }else{
          that.removeWishList(id);
        }
      }
    });

    // Input Checked
    window.$('body').on('change', '.category input[type="checkbox"]', function (){
      let id = window.$(this).data('id');

      if (this.checked){
        that.filter.subCategory.push(id);
      }else{
        let index = that.filter.subCategory.indexOf(id);
        that.filter.subCategory.splice(index, 1);
      }

      that.getProducts();
    });
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    sorted () {
      return this.filter.sorted;
    },
    language(){
      return langStor.state.language
    },
    computedCategoryName() {
      if (!this.subCategories[0]?.parent?.translations || this.subCategories[0]?.parent?.translations.length === 0) {
        return this.subCategories[0]?.parent?.name || '';
      }
      
      const translation = this.subCategories[0]?.parent?.translations.find(t => t.locale.includes(this.language));
      return translation ? translation.name :  this.subCategories[0]?.parent?.name || '';
    },
  },
  watch: {
    categoryName(){
      this.filter = {
        price: [0, 1000],
        sorted: '',
        subCategory: [],
      };
      this.getSubcategories();
      this.getProducts();
    },
    sorted(){
      this.getProducts();
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
/* Background */
.category{
  text-align: left;
}
[data-direction = rtl] .value {
    text-align: right !important;
}
[data-direction = rtl] .sortby {
    gap: 15px !important;
}
[data-direction = rtl] .title img {
    margin-right: 0 !important;
    margin-left: 3px
}
[data-direction = rtl] .pro_title{
  font-size: 15px !important;
  min-height: 37px;
}
.pro_title{
  font-size: 15px !important;
  min-height: 64px;
}
.category .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.category .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.visability{
  visibility: hidden
}
.category .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.category .container{
  padding: 2rem .5rem;
}
/* Filter */
.category .filter .title{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.category .filter .title img{
  margin-right: .5rem;
}
.category .filter .title h3{
  color: black;
  font-size: 18px;
  font-weight: bold;
  margin: 0
}
.category .filter .sub-category .items{
  padding: 1rem 0;
  line-height: 2.5;
}
.category .filter .sub-category .no-subcategory{
  margin-top: 1rem;
  font-size: 16px;
  color: #1E181A;
}
.category .filter .sub-category .items .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category .filter .sub-category .items .item label{
  font-size: 16px;
  color: black;
}
.category .filter .sub-category .items .item input[type="checkbox"]{
  accent-color: #B2CC9B;
}
.category .filter .price{
  margin-top: 1rem;
  line-height: 2.5;
}
.category .filter .price .value{
  padding: 1rem 0;
  color: black;
}
.category .filter .price .value p{
  font-size: 16px;
  color: #1E181A;
  margin-bottom: 0;
}
.v-card{
  box-shadow: none !important;
}
@media (max-width: 767px){
  .v-card{
    width: 15rem;
  }
}
@media (max-width: 300px){
  .v-card{
    width: 10rem;
  }
}
.v-card-text{
  padding: 1rem .2rem !important;
}
.v-slider.v-input--horizontal .v-slider-track__background{
  height: 1px !important;
  opacity: 1;
  background-color: #444444;
}
.v-slider.v-input--horizontal .v-slider-track__fill{
  background-color: #1E181A;
  opacity: 1;
  height: 3px !important;
}
.v-slider-thumb__surface{
  background-color: #FFF !important;
  border: 2px solid #1E181A ;
}
.v-slider-thumb__surface.elevation-2{
  box-shadow: none !important;
}
/* Content */
.category .content .details{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.category .content .details > span,
.category .content .details .sortby span{
  color: #1E181A;
}
.category .content .details > span{
  font-size: 22px;
}
.category .content .details .sortby{
  display: flex;
  align-items: center;
}
.category .content .details .sortby span{
  font-size: 16px;
  margin-right: .5rem;
}
.category .content .details .sortby .form-select{
  color: #1E181A;
  font-size: 16px;
  border: 1px solid #1E181A;
  border-radius: 10px;
}
.category .content .details .sortby span,
.category .content .details .sortby .form-select{
  width: -webkit-fill-available;
}
.category .content .cards{
  padding: 1rem 0;
}
.category .content .cards .row > div{
  margin-bottom: 1rem;
}
.category .content .cards .card,
.category .content .cards .card > img{
  border-radius: 10px;
}
.category .content .cards .card{
  padding: .8rem;
  height: 100%;
  box-shadow: 6px 10px 25px 0 rgba(0, 0, 0, .1);
  border: none;
  cursor: pointer;
}
.category .content .cards .card > img{
  width: 100%;
}
.category .content .cards .card h5{
  color: black;
  font-size: 18px;
  margin: .8rem 0;
}
.category .content .cards .card .prefix{
  display: flex;
  justify-content: space-between;
  align-items: center;
  
}
.category .content .cards .card .prefix span{
  font-size: 20px;
  font-weight: bold;
  color: black;
}
.category .content .cards .card .prefix img{
  background-color: #F7F6E2;
  padding: .5rem;
  border-radius: 10px;
}
.category .content .cards .card .old-price{
  color: #A2A2A2;
  font-size: 16px;
  text-decoration: line-through;
  text-align: left;
}
/* No Data */
.category .content .no-data{
  background-color: #F7F6E2;
  width: 29rem;
  margin: 15vh auto 0;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .category .content .no-data{
    width: 90%;
  }
}
.category .content .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
.category .content .no-data p{
  color: #6F825F;
  font-size: 16px;
}
</style>