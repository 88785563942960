<template>
  <div class="exchange-modal" id="exchange-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="ExchangeModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div v-if="step == 1" class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Return & Exchange</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="steps">
              <div class="step active">
                <span>1</span>
                <span>Select Item</span>
              </div>
              <hr />
              <div class="step">
                <span>2</span>
                <span>Option</span>
              </div>
            </div>
            <div class="items">
              <div class="item" v-for="product in products" :key="product.id" :data-id="product.id" :data-product="product.product_id">
                <div v-if="product.status == null">
                  <div class="product">
                    <img :src="product.image" alt="Product Image">
                    <div class="details">
                      <p>{{ product.name }}</p>
                      <span v-if="product.product_color_size.size">Size: {{ product.product_color_size.size.name }}</span>
                      <span v-if="product.product_color_size.color">Color: {{ product.product_color_size.color.name }}</span>
                    </div>
                  </div>
                  <input type="checkbox" />
                </div>
              </div>
            </div>
            <div class="reason">
              <h3>Enter Reason Why You Want To Refund</h3>
              <textarea v-model="reason" rows="5" placeholder="Enter Here"></textarea>
            </div>
            <button
              class="next-btn"
              @click="moveNextStep"
            >Next</button>
          </div>
          <div class="error-message">
            {{ errorMessage }}
          </div>
        </div>

        <!-- Step 2 -->
        <div v-else class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Choose Options</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="steps">
              <div class="step active">
                <span>1</span>
                <span>Select Item</span>
              </div>
              <hr class="active" />
              <div class="step active">
                <span>2</span>
                <span>Option</span>
              </div>
            </div>
            <div class="elements">
              <div class="element" v-for="item,index in items" :key="item.id" :data-id="item.id">
                <h2>Item {{ index + 1 }}</h2>
                <h3>{{ item.name }}</h3>
                <h4>Size*</h4>
                <div class="box size">
                  <span v-for="size in item.sizes" :key="size.id" :data-id="size.id" :data-name="size.name">{{ size.name }}</span>
                </div>
                <h4>Color*</h4>
                <div class="box color">
                  <span v-for="color in item.colors" :key="color.id" :data-id="color.id" :data-name="color.name">{{ color.name }}</span>
                </div>
              </div>
            </div>
            <hr />
            <p class="terms">By Continuing up you accept all terms & conditions of <span>Tiny Cola</span>!</p>
            <div class="buttons">
              <button
                class="prev-btn"
                @click="movePrevStep"
              >Back</button>
              <button
                class="exchange-btn"
                @click="sendData"
              >Update</button>
            </div>
            <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal" style="display: none;"></button>
          </div>
          <div class="error-message">
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>

    <message-modal content="Request sent successfully.Our team will review it soon!"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'

import store from '@/store'

export default {
  name: "ExchangeView",
  props: ['order_id', 'products'],
  data() {
    return {
      reason: '',
      carts: [],
      items: [],
      step: 1,
      errorMessage: '',
    }
  },
  methods: {
    moveNextStep(){
      this.errorMessage = '';
      if(this.reason != '' && this.carts.length > 0){
        for(let i = 0; i < this.carts.length; i++)
          this.getProduct(this.carts[i]);
        this.step += 1;
      
        console.log(this.carts);
      }
      else
        this.errorMessage = 'You Must Fill Information First.';
    },
    movePrevStep(){
      this.errorMessage = '';
      this.step -= 1;
    },
    getColorSizeId(cart){
      for(let j = 0; j < this.items.length; j++){
        if(this.items[j].id == cart.product_id){
          for(let i = 0; i < this.items[j].product_color_size.length; i++){
            if((cart.variant == 'color' && this.items[j].product_color_size[i].color.id == cart.color) ||
              (cart.variant == 'size' && this.items[j].product_color_size[i].size.id == cart.size) ||
              (cart.variant == 'both' && this.items[j].product_color_size[i].color.id == cart.color &&
                this.items[j].product_color_size[i].size.id == cart.size)){

              cart.color_size_id = this.items[j].product_color_size[i].id;
              cart.stock = this.items[j].product_color_size[i].stock;
              break;
            }
          }
          break;
        }
      }
    },
    getProduct(cart) {
      fetch(this.serverURL + 'api/catalog/products/' + cart.product_id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200){
            this.items.push(data.data);

            if(data.data.colors.length == 0)
              cart.variant = 'size';
            else if(data.data.sizes.length == 0)
              cart.variant = 'color';
            else
              cart.variant = 'both';
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    sendData(){
      this.errorMessage = '';
      let ok = true;
      for(let i = 0; i < this.carts.length; i++){
        if((this.carts[i].variant == 'both' && (this.carts[i].color == -1 || this.carts[i].size == -1)) ||
        (this.carts[i].variant == 'color' && this.carts[i].color == -1) || 
        (this.carts[i].variant == 'size' && this.carts[i].size == -1)){
          ok = false;
          break;
        }
      }
      console.log(this.carts, ok);
      if(ok){
        this.errorMessage = '';

        for(let i = 0; i < this.carts.length; i++){
          this.getColorSizeId(this.carts[i]);
        }

        let obj = {
          order_id: this.order_id,
          reason: this.reason,
          items: []
        };

        for(let i = 0; i < this.carts.length; i++){
          obj.items.push({
            order_product_id: this.carts[i].id,
            new_product_color_size_id: this.carts[i].color_size_id
          });
        }

        fetch(this.serverURL + 'api/exchange-requests', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: JSON.stringify(obj)
        })
          .then(response => response.json())
          .then(data => {
            if(data.status == 201){
              window.$('.exchange-modal #openModal').click();
            }
          })
          .catch(error => {
            console.log(error);
          })
      }else{
        this.errorMessage = 'You Must Fill All Information.';
      }
    },
  },
  mounted() {
    let that = this;

    // On Color Button Clicked
    window.$('body').on('click', '.exchange-modal .modal .modal-body .elements .element .box.color span', function (){
      window.$(this).addClass('active').siblings().removeClass('active');
      let id = window.$(this).parents('.element').data('id');
      let color_id = window.$(this).data('id');
      for(let i = 0; i < that.carts.length; i++){
        if(that.carts[i].product_id == id){
          that.carts[i].color = color_id;
          break;
        }
      }
    });

    // On Size Button Clicked
    window.$('body').on('click', '.exchange-modal .modal .modal-body .elements .element .box.size span', function (){
      window.$(this).addClass('active').siblings().removeClass('active');
      let id = window.$(this).parents('.element').data('id');
      let size_id = window.$(this).data('id');
      for(let i = 0; i < that.carts.length; i++){
        if(that.carts[i].product_id == id){
          that.carts[i].size = size_id;
          break;
        }        
      }
    });

    // Input Checked
    window.$('body').on('change', '.exchange-modal .modal .modal-body .items .item input[type="checkbox"]', function (){
      that.errorMessage = '';
      let id = window.$(this).parents('.item').data('id');
      let product_id = window.$(this).parents('.item').data('product');

      if(this.checked){
        let obj = {
          id: id,
          product_id: product_id,
          color_size_id: -1,
          stock: 0,
          variant: '',
          color: -1,
          size: -1
        };
        that.carts.push(obj);
      }else{
        let index = -1;
        for(let i = 0; i < that.carts.length; i++){
          if(that.carts[i].id == id){
            index = i;
            break;
          }
        }
        that.carts.splice(index, 1);
      }
    });
  },
  components: {
    MessageModal,
  },
};
</script>

<style>
.exchange-modal .modal{
  background-color: #E7E7E7;
}
.exchange-modal .modal .modal-content {
  border-radius: 10px;
  padding: 2rem;
  border: none;
}
.exchange-modal .modal .modal-header{
  border-bottom: none;
  padding: 0; 
}
.exchange-modal .modal .modal-title {
  color: #6F825F;
  font-size: 18px;
  font-weight: bold;
}
/* Body */
.exchange-modal .modal .modal-body{
  text-align: left;
  color: #1E181A;
  font-size: 15px;
  padding: 0;
}
/* Steps */
.exchange-modal .modal .modal-body .steps{
  margin-top: 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.exchange-modal .modal .modal-body .steps hr {
  background-color: #EEEEEE;
  opacity: 1;
  width: 33%;
  border: none;
  height: 1px;
  position: absolute;
  top: 1.7rem;
  z-index: 1;
}
.exchange-modal .modal .modal-body .steps hr.active{
  background-color: #B2CC9B;
}
.exchange-modal .modal .modal-body .steps .step{
  text-align: center;
  z-index: 2;
}
.exchange-modal .modal .modal-body .steps .step span:first-of-type{
  font-size: 16px;
  font-weight: bold;
  border-radius: 50%;
  padding: .8rem 1.1rem;
  background-color: #EEEEEE;
  color: #9B9A9A;
}
.exchange-modal .modal .modal-body .steps .step.active span:first-of-type{
  background-color: #B2CC9B;
  color: #6F825F;
}
.exchange-modal .modal .modal-body .steps .step span:last-of-type{
  color: #9B9A9A;
  font-size: 16px;
  display: block;
  margin-top: 1rem;
}
.exchange-modal .modal .modal-body .steps .step.active span:last-of-type{
  color: #1E181A;
}

/* Items */
.exchange-modal .modal .modal-body .items{
  margin-top: 1rem;
}
.exchange-modal .modal .modal-body .items .item{
  border-radius: 0;
  border: none;
}
.exchange-modal .modal .modal-body .items .item > div{
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.exchange-modal .modal .modal-body .items .item:not(:first-of-type){
  margin-top: 2rem;
}
.exchange-modal .modal .modal-body .items .item .product{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.exchange-modal .modal .modal-body .items .item .product img{
  width: 5rem;
  margin-right: .5rem
}
.exchange-modal .modal .modal-body .items .item .product .details p{
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: .5rem;
}
.exchange-modal .modal .modal-body .items .item .product .details span{
  color: #B2CC9B;
  font-size: 14px;
  display: block;
}
.exchange-modal .modal .modal-body .items .item .product .details span:first-of-type{
  margin-bottom: .5rem;
}
.exchange-modal .modal .modal-body .items .item input[type='checkbox']{
  accent-color: #B2CC9B;
}
/* Reason */
.exchange-modal .modal .modal-body .reason{
  margin-top: 1rem;
}
.exchange-modal .modal .modal-body .reason h3{
  color: #6F825F;
  font-size: 14px;
  margin-bottom: 1rem;
}
.exchange-modal .modal .modal-body .reason textarea{
  width: 100%;
  background-color: #F5F5F5;
  border-radius: 10px;
  padding: 1rem;
  color: #A2A2A2;
  font-size: 14px;
}

/* Elements */
.exchange-modal .modal .modal-body .elements{
  margin-top: 1rem;
}
.exchange-modal .modal .modal-body .elements .element:not(:first-of-type){
  margin-top: 1.5rem;
}
.exchange-modal .modal .modal-body .elements .element h2,
.exchange-modal .modal .modal-body .elements .element h3{
  color: #000;
}
.exchange-modal .modal .modal-body .elements .element h3{
  font-size: 14px;
  margin-bottom: 0;
}
.exchange-modal .modal .modal-body .elements .element h4,
.exchange-modal .modal .modal-body .elements .element h2{
  margin: .7rem 0;
  font-size: 16px;
  font-weight: bold;
}
.exchange-modal .modal .modal-body .elements .element h4{
  color: #313131;
}
.exchange-modal .modal .modal-body .elements .element .box{
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
.exchange-modal .modal .modal-body .elements .element .box span{
  color: #A2A2A2;
  font-size: 18px;
  background-color: #F7F6E2;
  padding: .5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
}
.exchange-modal .modal .modal-body .elements .element .box span:not(:last-of-type){
  margin-right: 1rem;
}
.exchange-modal .modal .modal-body .elements .element .box span.active{
  color: #FFF;
  background-color: #B2CC9B;
  border-color: #B2CC9B;
}


.exchange-modal .modal .modal-body > hr{
  margin: 2rem 0 1rem;
}
.exchange-modal .modal .modal-body .terms{
  color: #6F825F;
  font-size: 12px;
  margin-bottom: 0;
  margin-top: .5rem;
}
.exchange-modal .modal .modal-body .terms span{
  font-weight: bold;
}
.exchange-modal .modal .next-btn,
.exchange-modal .modal .prev-btn,
.exchange-modal .modal .exchange-btn{
  border: none;
  font-size: 14px;
  font-weight: bold;
  border-radius: 10px;
  padding: .7rem;
  display: block;
  width: 7rem;
}
.exchange-modal .modal .next-btn,
.exchange-modal .modal .exchange-btn{
  color: #FFF;
  background-color: #B2CC9B;
}
.exchange-modal .modal .next-btn{
  margin: 1rem auto 0;
}
.exchange-modal .modal .buttons{
  margin-top: 1rem;
  display: flex;
  justify-content: space-evenly;
}
.exchange-modal .modal .prev-btn{
  background-color: #EAF1E4;
  color: #6F825F;
}
</style>
