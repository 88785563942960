import { reactive } from 'vue'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = ({
  state: reactive({
    accessToken: null,
    userInfo: null,
    appLang: null,
    appMode: null,
    sessionId:null
  }),
  getters: {
    getAccessToken() {
      return store.state.accessToken
    },
    getUserInfo() {
      return store.state.userInfo
    },
    getAppLang() {
      return store.state.appLang
    },
    getAppMode() {
      return store.state.appMode
    },
    getSessionId() {
      return store.state.sessionId
    }
  },
  mutations: {
  },
  actions: {
    initializeAccessToken() {
      store.state.accessToken = localStorage.getItem('token')?localStorage.getItem('token'):''
    },
    initializeUserInfo() {
      store.state.userInfo = localStorage.getItem('user_info')?JSON.parse(localStorage.getItem('user_info')):null
    },
    initializeAppLang() {
      store.state.appLang = localStorage.getItem('app_lang')?localStorage.getItem('app_lang'):'en'
    },
    initializeAppMode() {
      store.state.appMode = localStorage.getItem('app_mode')?localStorage.getItem('app_mode'):'light'
    },
    initializeSession() {
      let randomNumberString = Math.random().toString(36).substring(2, 10);
  
      // Generate a string with the current timestamp
      const date = new Date();
      let currentTimeStamp = date.getTime().toString();

      store.state.sessionId = randomNumberString + currentTimeStamp
    }
  },
  plugins: [vuexLocal.plugin]
})

export default store