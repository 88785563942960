<template>
  <div class="home" id="home">
    <Loading v-model:active="isLoading"
                 :can-cancel="true"
                 :is-full-page="true"
                 color="#E63D36"
                 background-color="#000"
                 loader="bars"
                 />
      <a href="#">
        <img class="float-icon" src="@/assets/icons/home/whatsapp.svg" alt="Whatsapp Icon">
      </a>
      <explain-section />
      <category-section :home=home  />
      <best-selling-section :home=home  />
      <new-arrivals-section :home=home  />
      <super-sale-section :home=home  />
      <you-like-section :home=home  />
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import ExplainSection from '@/components/home/ExplainSection.vue'
import CategorySection from '@/components/home/CategorySection.vue'
import BestSellingSection from '@/components/home/BestSellingSection.vue'
import NewArrivalsSection from '@/components/home/NewArrivalsSection.vue'
import SuperSaleSection from '@/components/home/SuperSaleSection.vue'
import YouLikeSection from '@/components/home/YouLikeSection.vue'
import store from '@/store'

export default {
  name: "HomeView",
  data() {
    return {
      isLoading: false,
      home:{}
    }
  },
  methods: {
    getHomeContent() {
      fetch(this.serverURL + 'api/cms/pages/home', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
          .then(response => response.json())
          .then(data => {
            if (data.status == 200)
              this.home = data.data;
          })
          .catch(error => {
            console.log(error);
          })
    },
  },
  beforeMount() {
    this.getHomeContent()
  },
  mounted() {},
    components: {
      ExplainSection,
      CategorySection,
      BestSellingSection,
      NewArrivalsSection,
      SuperSaleSection,
      YouLikeSection,
      Loading,
    },
};
</script>

<style>
.float-icon{
  position: fixed;
  right: .5rem;
  top: 70vh;
  width: 3rem;
  z-index: 10;
}
</style>