<template>
  <div class="cart" id="reset-password">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Cart') }}</h3>
    </div>
    <div class="container">
      <div v-if="carts.length > 0" class="row">
        <div class="col-xl-8">
          <div class="columns">
            <div class="row">
              <div class="col-4">
                {{ $t('Product') }}
              </div>
              <div class="col-2">
                {{ $t('Price') }}
              </div>
              <div class="col-2">
                {{ $t('Quantity') }}
              </div>
              <div class="col-2">
                {{ $t('Total') }}
              </div>
              <div class="col-2">
              </div>
            </div>
          </div>
          <div class="cards">
            <div v-for="cart in carts" :key="cart.cart_id" :data-id="cart.cart_id" :data-stock="cart.stock" class="card">
              <div class="row">
                <div class="col-4">
                  <div class="product">
                    <img :src="cart.image" alt="Product Image">
                    <div class="details">
                      <p>{{ cart?.name  }}</p>
                      <span>Size: {{ cart.size?.name?cart.size.name:cart.size?cart.size:'' }}</span>
                      <span>Color: {{ cart.color?.name?cart.color.name:cart.color?cart.color:'' }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="price">
                    <span>{{ cart.offer_price && handleDate(cart?.offer_price_end)?cart.offer_price:cart.price }} {{ $t('KD') }}</span>
                  </div>
                </div>
                <div class="col-2">
                  <div class="quantity">
                    <div class="counter">
                      <img class="button mines" src="@/assets/icons/product/mines.svg" alt="Mines Icon">
                      <span class="number">{{ cart.cart_quantity }}</span>
                      <img class="button plus" src="@/assets/icons/product/plus.svg" alt="Plus Icon">
                    </div>
                  </div>
                </div>
                <div class="col-2">
                  <div class="total-price">
                    <span>{{ (cart.offer_price && handleDate(cart?.offer_price_end)?cart.offer_price:cart.price) * parseInt(cart.cart_quantity) }} {{ $t('KD') }}</span>
                  </div>
                </div>
                <div class="col-2">
                  <div class="operations" @click="current_id=cart.cart_id" data-bs-toggle="modal" data-bs-target="#DeleteItemModal">
                    <img src="@/assets/icons/delete.svg" alt="Delete Icon">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4">
          <div class="total">
            <div class="price">
              <span>{{ $t('Products Total') }}</span>
              <span>{{ totalPrice }} {{ $t('KD') }}</span> 
            </div>
            <a>{{ $t('Proceed To Checkout') }}</a>
          </div>
        </div>
      </div>
      <div v-else class="no-data">
        <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon">
        <h3>{{ $t('No Products In Cart') }}</h3>
        <!-- <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p> -->
      </div>
    </div>
  </div>
  <DeleteItemModal @deleteItem="deleteTheItem(current_id)"></DeleteItemModal>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import langStor from '@/langStore'

import store from '@/store'
import DeleteItemModal from '@/views/deleteCart.vue'

export default {
  name: "CartView",
  data() {
    return {
      carts: [],
      totalPrice: 0,
      current_id:'',
      isLoading: false
    }
  },
  methods: {
    getCarts() {
      // <<<<<<<<<if unAuth>>>>>>>>>>>>>>>>>>>>
        console.log(store.state.userInfo==null,'user');
      if(store.state.userInfo==null){
        const localCart = localStorage.getItem('cartProducts');
        if(localCart){
          this.carts=JSON.parse(localCart);
          for(let i = 0; i < this.carts.length; i++){
            this.totalPrice += (this.carts[i]?.offer_price && this.handleDate(this.carts[i]?.offer_price_end)?this.carts[i].offer_price:this.carts[i].price) * parseInt(this.carts[i]?.cart_quantity);
          }
          console.log(this.carts);
        }
      }else{
        this.isLoading = true;
        fetch(this.serverURL + 'api/cart/products', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: JSON.stringify({
            session_id: localStorage.getItem('sessionId'),
          })
        })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;
            if(data.status == 200){
              this.carts = data.data;
              for(let i = 0; i < this.carts.length; i++){
                this.totalPrice += (this.carts[i].offer_price && this.handleDate(this.carts[i]?.offer_price_end)?this.carts[i].offer_price:this.carts[i].price) * parseInt(this.carts[i].cart_quantity);
              }
            }
          })
          .catch(error => {
            console.log(error);
          })

      }
    },
    updateCart(id, quantity){
      fetch(this.serverURL + 'api/cart/products/' + id + '?quantity=' + quantity,{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify({
          session_id: localStorage.getItem('sessionId'),
          quantity: this.quantity,
          _method: 'PUT'
        })
      })
        .then(response => response.json())
        .then()
        .catch(error => {
          console.error(error);
        })
    },
    handleDate(value){
      const dateToCompare = new Date(value);

          // Get today's date
          const today = new Date();

          // Set the time to 00:00:00 for both dates to only compare the date part
          today.setHours(0, 0, 0, 0);
          dateToCompare.setHours(0, 0, 0, 0);

          // Compare dates
          if (dateToCompare < today) {
              return false
          } else {
            return true
      }
    },
    deleteCart(id){
     if(localStorage.getItem('token') && localStorage.getItem('token')!=''){
      fetch(this.serverURL + 'api/cart/products/' + id,{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify({
            session_id: localStorage.getItem('sessionId'),
            _method: 'DELETE'
        })
      })
        .then(response => response.json(this.getCarts()))
        .then()
        .catch(error => {
          console.error(error);
        })
     }else {
      let products = JSON.parse(localStorage.getItem('cartProducts'));

      if (products) {
          products = products.filter(product => product.id !== id);
          localStorage.setItem('cartProducts', JSON.stringify(products));
          this.getCarts()
      }
      this.getCarts()
     }
    },
    deleteTheItem(id){

        let newCarts = [];

        for(let i = 0; i < this.carts.length; i++){
          if (this.carts[i].cart_id == id){
            this.totalPrice -= ((this.carts[i].offer_price)&&this.handleDate(this.carts[i]?.offer_price_end)?this.carts[i].offer_price:this.carts[i].price) * this.carts[i].cart_quantity;
            console.log(this.totalPrice,'5');
            continue;
          }
          newCarts.push(this.carts[i]);
        }

        this.carts = newCarts;
        this.deleteCart(id);
    }
  },
  beforeMount() {
      // this.getCarts();
  },
  mounted() {
    this.getCarts();
    let that = this;

    // Mines Clicked
    window.$('body').on('click', '.cart .container .cards .card .quantity .counter .mines', function (e){
      e.stopImmediatePropagation();
      let id = window.$(this).parents('.card').data('id');

        for(let i = 0; i < that.carts.length; i++){
          if (that.carts[i].cart_id == id){
            console.log('1');
            if(that.carts[i].cart_quantity > 1){
              console.log('1');
              that.carts[i].cart_quantity -= 1;
              that.totalPrice -= (that.carts[i].offer_price && that.handleDate(that.carts[i]?.offer_price_end)?that.carts[i].offer_price:that.carts[i].price);
              console.log(this.totalPrice,'3');

              that.updateCart(id, that.carts[i].cart_quantity);
            }
            break;
          }
        }
    });

    // Plus Clicked
    window.$('body').on('click', '.cart .container .cards .card .quantity .counter .plus', function (e){
      e.stopImmediatePropagation();
      let stock = window.$(this).parents('.card').data('stock');
      let id = window.$(this).parents('.card').data('id');
        
        for(let i = 0; i < that.carts.length; i++){
          if (that.carts[i].cart_id == id){
            if(that.carts[i].cart_quantity < stock){
              that.carts[i].cart_quantity =  parseInt(that.carts[i].cart_quantity) + 1;
              that.totalPrice += (that.carts[i].offer_price && that.handleDate(that.carts[i]?.offer_price_end)?that.carts[i].offer_price:that.carts[i].price);
              console.log(this.totalPrice,'4');
              that.updateCart(id, that.carts[i].cart_quantity);
            }
            break;
          }
        }
    });

    // Delete Icon Clicked
    // window.$('body').on('click', '.cart .container .cards .card .operations img', function (){
    //   let id = window.$(this).parents('.card').data('id');

    //     let newCarts = [];

    //     for(let i = 0; i < that.carts.length; i++){
    //       if (that.carts[i].cart_id == id){
    //         that.totalPrice -= ((that.carts[i].offer_price)&&that.handleDate(that.carts[i]?.offer_price_end)?that.carts[i].offer_price:that.carts[i].price) * that.carts[i].cart_quantity;
    //         console.log(this.totalPrice,'5');
    //         continue;
    //       }
    //       newCarts.push(that.carts[i]);
    //     }

    //     that.carts = newCarts;
    //     that.deleteCart(id);
    // });
    
    // On Proceed To Check Clicked
    window.$('body').on('click', '.cart .container .total a', function (){
      if(that.token != ''){
        localStorage.setItem('products_total', that.totalPrice);
        console.log(this.totalPrice,'6');
        that.$router.push({ name:'Checkout' });
      }else{
        that.$router.push({ name:'Login' });
      }
    });
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    language(){
      return langStor.state.language
    }
  },
  components: {
    Loading,DeleteItemModal
  },
};
</script>

<style>
/* Background */
.cart .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.cart .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.cart .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.cart .container{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Columns */
.cart .container .columns{
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  color: #1E181A;
}

/* Cards */
.cart .container .cards{
  text-align: left;
}
.cart .container .cards .card{
  padding-bottom: 2rem;
  margin-top: 2rem;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid #9B9A9A;
}
.cart .container .cards .card .row{
  align-items: center;
}
.cart .container .cards .card .product{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.cart .container .cards .card .product img{
  width: 5rem;
  margin-right: .5rem
}
.cart .container .cards .card .product .details p{
  font-size: 16px;
  font-weight: bold;
  color: black;
  margin-bottom: .5rem;
}
.cart .container .cards .card .product .details span{
  color: #B2CC9B;
  font-size: 14px;
  display: block;
}
.cart .container .cards .card .product .details span:first-of-type{
  margin-bottom: .5rem;
}
.cart .container .cards .card .price span,
.cart .container .cards .card .total-price span{
  font-size: 16px;
  color: black;
}
.cart .container .cards .card .quantity .counter{
  background-color: #EAF1E4;
  padding: .5rem;
  color: #6F825F;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.cart .container .cards .card .quantity .counter .button{
  border-radius: 50%;
  padding: .2rem;
  width: 22px;
  border: 2px solid #6F825F;
  cursor: pointer;
}
.cart .container .cards .card .quantity .counter .button:first-of-type{
  padding: .49rem .3rem;
}
.cart .container .cards .card .quantity .counter .number{
  font-size: 16px;
  font-weight: bold;
  margin: 0 .5rem;
}
.cart .container .cards .card .operations img{
  cursor: pointer;
}

/* Totla */
.cart .container .total{
  background-color: #F7F6E2;
  border-radius: 10px;
  padding: 2rem;
}
@media(max-width: 1200px){
  .cart .container .total{
    margin-top: 2rem;
  }
}
.cart .container .total .price{
  font-size: 18px;
  color: #6F825F;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.cart .container .total a{
  cursor: pointer;
  text-decoration: none;
  color: #FFF;
  font-size: 16px;
  font-weight: bold;
  background-color: #B2CC9B;
  border-radius: 10px;
  padding: .8rem .5rem;
  margin-top: 1rem;
  display: block;
}

/* No Data */
.cart .container .no-data{
  background-color: #F7F6E2;
  width: 29rem;
  margin: 15vh auto 0;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .cart .container .no-data{
    width: 90%;
  }
}
.cart .container .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
.cart .container .no-data p{
  color: #6F825F;
  font-size: 16px;
}
</style>