<template>
  <div class="add-address" id="add-address">
    <!-- Modal -->
    <div
      class="modal fade"
      id="addAddressModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="title">
            <h2>{{$t('Add New Address')}}</h2>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <form @submit.prevent="SendData">
            <div class="row">
              <div class="col-12">
                <label>{{$t('Address Title')}}*</label>
                <input type="text" v-model="addressData.title" :placeholder="$t('Title')" required/>
              </div>
              <div class="col-12">
                <label>{{$t('Area')}}*</label>
                <v-select
                  v-model="addressData.area_id"
                  :items="areas"
                  item-title="name"
                  item-value="id"
                  variant="outlined"
                  required
                ></v-select>
              </div>
              <div class="col-12">
                <label>{{$t('Block')}}*</label>
                <input type="text" v-model="addressData.block" :placeholder="$t('Block')" required/>
              </div>
              <div class="col-12">
                <label>{{$t('Street')}}*</label>
                <input type="text" v-model="addressData.street" :placeholder="$t('Street')" required/>
              </div>
              <div class="col-12">
                <label>{{$t('Avenue(Optional)')}}</label>
                <input type="text" v-model="addressData.avenue" :placeholder="$t('Avenue')" />
              </div>
              <div class="col-12">
                <div class=buttons>
                  <button data-value="house">{{$t('House')}}</button>
                  <button data-value="apartment">{{$t('Apartment')}}</button>
                  <button data-value="office">{{$t('Office')}}</button>
                </div>
              </div>
              <div v-if="addressData.accommodation_type == 'apartment' || addressData.accommodation_type == 'office'" class="col-12">
                <label>{{$t('Building Number')}}*</label>
                <input type="text" v-model="addressData.building_number" :placeholder="$t('Building Number')" required/>
              </div>
              <div v-if="addressData.accommodation_type == 'apartment' || addressData.accommodation_type == 'office'" class="col-12">
                <label>{{$t('Floor')}}*</label>
                <input type="text" v-model="addressData.floor" :placeholder="$t('Floor')" required/>
              </div>
              <div v-if="addressData.accommodation_type == 'house'" class="col-12">
                <label>{{$t('House Number')}}*</label>
                <input type="text" v-model="addressData.house_number" :placeholder="$t('House Number')" required/>
              </div>
              <div v-if="addressData.accommodation_type == 'office'" class="col-12">
                <label>{{$t('Office Number(Optional)')}}</label>
                <input type="text" v-model="addressData.office_number" :placeholder="$t('Office Number')" />
              </div>
              <div v-if="addressData.accommodation_type == 'apartment'" class="col-12">
                <label>{{$t('Apartment Number(Optional)')}}</label>
                <input type="text" v-model="addressData.apartment_number" :placeholder="$t('Apartment Number')" />
              </div>
              <div class="col-12">
                <label>{{$t('Extra Directions(Optional)')}}</label>
                <input type="text" v-model="addressData.extra_directions" :placeholder="$t('Extra Directions')" />
              </div>
            </div>
            <div class="error-message">
              <p v-for="error in errorMessages" :key="error">{{ error }}</p>
            </div>            
            <input type="submit" class="submit-btn" :value="$t('Add')" :disabled="isLoading ? true:false" />
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import store from '@/store'

export default {
  name: "AddAddressModal",
  data() {
    return {
      addressData: {
        title: '',
        area_id: '',
        block: '',
        street: '',
        avenue: '',
        accommodation_type: '',
        building_number: '',
        floor: '',
        apartment_number: '',
        house_number: '',
        office_number: '',
        extra_directions: '',
      },
      areas: [],
      errorMessages: [],
      isLoading: false
    }
  },
  methods: {
    getAreas() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/areas', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if(data.status == 200)
            this.areas = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    SendData() {
      if(this.addressData.accommodation_type != ''){
        this.isLoading = true;

        let formData = new FormData();
        formData.append("title", this.addressData.title);
        formData.append("area_id", this.addressData.area_id);
        formData.append("block", this.addressData.block);
        formData.append("street", this.addressData.street);
        if(this.addressData.avenue)
          formData.append("avenue", this.addressData.avenue);
        formData.append("accommodation_type", this.addressData.accommodation_type);
        if(this.addressData.accommodation_type != 'house'){
          formData.append("building_number", this.addressData.building_number);
          formData.append("floor", this.addressData.floor);
          if(this.addressData.accommodation_type == 'office')
            formData.append("office_number", this.addressData.office_number);
          else
            formData.append("apartment_number", this.addressData.apartment_number);
        }else
          formData.append("house_number", this.addressData.house_number);
        if(this.addressData.extra_directions)
          formData.append("extra_directions", this.addressData.extra_directions);
        
        fetch(this.serverURL + 'api/addresses',{
          method:  'POST',
          headers: {
            Authorization: `Bearer ${store.state.accessToken}`,
          },
          body: formData,
        })
          .then(response => response.json())
          .then(data => {
            this.isLoading = false;

            if (data.status == 201){
              location.reload();
            }else{
              let messages = [];

              for(let propt in data.errors){
                messages.push(data.errors[propt].join(' '));
              }
              this.errorMessages = messages;
            }
          })
          .catch(error => {
            console.error(error);
          })
      }else{
        this.errorMessages.push('You Must Choose Accommodation type');
      }
    }
  },
  beforeMount() {
    this.getAreas();
  },
  mounted() {
    let that = this;

    // On Button Clicked
    window.$('.add-address .modal form .buttons button').on('click', function (e){
      e.preventDefault();
      window.$(this).addClass('active').siblings().removeClass('active');
      that.addressData.accommodation_type = window.$(this).data('value');
    });
  },
  components: {},
};
</script>

<style>
.add-address .modal{
  background-color: #E7E7E7;
}
.add-address .modal .modal-content {
  border-radius: 5px;
  padding: 3rem;
  border: none;
}
@media(max-width: 576px){
  .add-address .modal .modal-content {
    padding: 2rem;
  }
}
.add-address .modal .title{
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem !important;
}
.add-address .modal h2{
  font-weight: bold;
  font-size: 18px;
  color: black;
  margin-bottom: 0;
}

/* Form */
.add-address .modal form label {
  display: block;
  margin: 1rem 0 .5rem 0;
  color: #6F825F;
  font-size: 14px;
}
.add-address .modal form input,
.add-address .modal form .v-select {
  border: none;
  background-color: #F5F5F5;
  font-size: 14px;
  padding: .8rem 1.5rem;
  border-radius: 10px;
  width: 100%;
}
.add-address .modal form .buttons{
  margin-top: 1rem;
  display: flex;
  justify-content: space-between;
}
.add-address .modal form .buttons button{
  cursor: pointer;
  padding: .5rem;
  font-size: 16px;
  font-weight: bold;
  width: 7rem;
  border-radius: 10px;
  background-color: #FFF;
  border: 1px solid #B2CC9B;
  color: #B2CC9B;
}
.add-address .modal form .buttons button.active{
  background-color: #B2CC9B;
  color: #FFF;
}
.add-address .modal form .submit-btn {
  border-radius: 10px;
  padding: .6rem 1rem;
  color: #fff;
  box-shadow: 0 1px 5px #f1e7e7;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin: 1rem auto 0;
  width: 12rem;
  background-color: #B2CC9B;
}

/* V-Select */
.add-address .modal form .v-select{
  padding: 0;
}
.add-address .modal form .v-select .v-field__input{
  padding: .8rem 1.5rem;
  min-height: 2.8rem;
}
.add-address .modal form .v-select .v-field{
  border-radius: 10px;
}
.add-address .modal form .v-select span,
.add-address .modal form .v-select .v-icon{
  color: black;
}
.add-address .modal form .v-select input{
  border: none;
  background-color: transparent;
  padding: 0;
  border-radius: 0;
  width: 100%;
}
.add-address .modal form .v-select label{
  margin: 0 1rem;
}
.add-address .modal form .v-select .v-input__details{
  display: none;
}
</style>
