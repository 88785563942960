<template>
  <div class="my-account" id="my-account">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Account') }}</h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="details">
            <div class="info">
              <h3>{{ info.name }}</h3>
              <h5>{{ info.email }}</h5>
              <h5>{{ info.phone }}</h5>
              <span class="logout" @click="logout">{{ $t('Logout') }}</span>
            </div>
            <div class="selections">
              <a href="#" data-name="orders" class="option active">
                <span>{{ $t('Orders') }}</span>
                <v-icon icon="mdi-chevron-right" />
              </a>
              <a href="#" data-name="addresses" class="option">
                <span>{{ $t('My Address') }}</span>
                <v-icon icon="mdi-chevron-right" />
              </a>
              <a href="#" data-name="wishList" class="option">
                <span>{{ $t('Wishlist') }}</span>
                <v-icon icon="mdi-chevron-right" />
              </a>
              <a href="#" data-name="editAccount" class="option">
                <span>{{ $t('Edit Account') }}</span>
                <v-icon icon="mdi-chevron-right" />
              </a>
              <a href="#" data-name="changePassword" class="option">
                <span>{{ $t('Change Password') }}</span>
                <v-icon icon="mdi-chevron-right" />
              </a>
            </div>
          </div>
        </div>
        <div class="col-md-8">
          <orders-section v-if="component == 'orders'" />
          <addresses-section v-if="component == 'addresses'" />
          <wishList-section v-if="component == 'wishList'" />
          <edit-account-section v-if="component == 'editAccount'" />
          <change-password-section v-if="component == 'changePassword'" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersSection from '@/components/myAccount/OrdersSection.vue';
import AddressesSection from '@/components/myAccount/AddressesSection.vue';
import wishListSection from '@/components/myAccount/wishListSection.vue';
import EditAccountSection from '@/components/myAccount/EditAccountSection.vue';
import ChangePasswordSection from '@/components/myAccount/ChangePasswordSection.vue';

import store from '@/store'

export default {
  name: "MyAccountView",
  data() {
    return {
      component: 'orders',
      isLoading: false
    }
  },
  methods: {
    logout() {
      fetch(this.serverURL + 'api/auth/logout',{
        method:  'POST',
        headers: {
          Authorization: `Bearer ${store.state.accessToken}`,
        }
      })
        .then(response => response.json())
        .then(() => {
          localStorage.removeItem('token');
          store.state.accessToken = '';
          localStorage.removeItem('user_info');
          localStorage.removeItem('cartProducts');
          store.state.userInfo = null;
          let randomNumberString = Math.random().toString(36).substring(2, 10);

          const date = new Date();
          let currentTimeStamp = date.getTime().toString();

          localStorage.setItem('sessionId', randomNumberString + currentTimeStamp);
          this.$router.push({ name: "Home"});
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  beforeMount() {},
  mounted() {
    let that = this;

    // Option Clicked
    window.$('.my-account .details .selections .option').on('click', function (e){
      e.preventDefault();
      window.$(this).addClass('active').siblings().removeClass('active');
      that.component = window.$(this).data('name');
    });
  },
  computed: {
    info () {
      return store.state.userInfo;
    },
  },
  components: {
    OrdersSection,
    AddressesSection,
    EditAccountSection,
    ChangePasswordSection,
    wishListSection
  },
};
</script>

<style>
/* Background */
.my-account .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.my-account .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.my-account .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.my-account > .container{
  padding-top: 2rem;
  padding-bottom: 2rem;
}

/* Details */
.my-account .details .info{
  padding: 1rem;
  text-align: left;
  border: 1px solid #9B9A9A;
  border-radius: 10px;
  margin-bottom: 1rem;
}
@media(max-width: 767px){
  .my-account .details{
    margin-bottom: 1rem;
  }
}
.my-account .details .info h5,
.my-account .details .info h3{
  color: black;
  margin-bottom: 1rem;
}
.my-account .details .info h3,
.my-account .details .info span{
  font-weight: bold;
}
.my-account .details .info h3{
  font-size: 24px;
}
.my-account .details .info h5{
  font-size: 16px;
}
.my-account .details .info span{
  color: #B2CC9B;
  font-size: 18px;
  cursor: pointer;
}
.my-account .details .selections{
  line-height: 3;
}
.my-account .details .selections .option{
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  color: #9B9A9A;
}
.my-account .details .selections .option.active{
  color: #B2CC9B;
}
</style>