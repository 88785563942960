<template>
  <div class="request-modal" id="request-modal">
    <!-- Modal -->
    <div
      class="modal fade"
      id="RequestModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Request Details</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="elements">
              <div class="element">
                <h2>Item 1</h2>
                <h3>Bezuto Dragon Ball Z Super Goku Frieza Action Figures</h3>
                <h4>Size</h4>
                <div class="box">
                  <span class="active">XL</span>
                </div>
                <h4>Color</h4>
                <div class="box">
                  <span class="active">Red</span>
                </div>
              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RequestView",
  data() {
    return {
    }
  },
  methods: {
  },
  mounted() {
  },
  components: {
  },
};
</script>

<style>
.request-modal .modal{
  background-color: #E7E7E7;
}
.request-modal .modal .modal-content {
  border-radius: 10px;
  padding: 2rem;
  border: none;
}
.request-modal .modal .modal-header{
  border-bottom: none;
  padding: 0; 
}
.request-modal .modal .modal-title {
  color: #6F825F;
  font-size: 18px;
  font-weight: bold;
}
/* Body */
.request-modal .modal .modal-body{
  text-align: left;
  color: #1E181A;
  font-size: 15px;
  padding: 0;
}

/* Elements */
.request-modal .modal .modal-body .elements{
  margin-top: 1rem;
}
.request-modal .modal .modal-body .elements .element:not(:first-of-type){
  margin-top: 1.5rem;
}
.request-modal .modal .modal-body .elements .element h2,
.request-modal .modal .modal-body .elements .element h3{
  color: #000;
}
.request-modal .modal .modal-body .elements .element h3{
  font-size: 14px;
  margin-bottom: 0;
}
.request-modal .modal .modal-body .elements .element h4,
.request-modal .modal .modal-body .elements .element h2{
  margin: .7rem 0;
  font-size: 16px;
  font-weight: bold;
}
.request-modal .modal .modal-body .elements .element h4{
  color: #313131;
}
.request-modal .modal .modal-body .elements .element .box{
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
.request-modal .modal .modal-body .elements .element .box span{
  color: #A2A2A2;
  font-size: 18px;
  background-color: #F7F6E2;
  padding: .5rem 1rem;
  border-radius: 10px;
}
.request-modal .modal .modal-body .elements .element .box span:not(:last-of-type){
  margin-right: 1rem;
}
.request-modal .modal .modal-body .elements .element .box span.active{
  color: #FFF;
  background-color: #B2CC9B;
  border-color: #B2CC9B;
}
</style>
