<template>
  <div class="reset-password" id="reset-password">
    <h2>{{ $t('Change password') }}</h2>
    <form @submit.prevent="changeUserPassword">
      <label>{{$t('Old Password')}}*</label>
      <input type="password" v-model="data.old_password" :placeholder="$t('Enter Here')" required>
      <label>{{$t('New Password')}}*</label>
      <input type="password" v-model="data.new_password" :placeholder="$t('Enter Here')" required>
      <label>{{$t('Confirm New Password')}}*</label>
      <input type="password" v-model="data.new_password_confirmation" :placeholder="$t('Enter Here')" required>
      <input type="submit" :value="$t('Reset')" :disabled="!!isLoading">
      <p class="error-message">{{ errorMessage }}</p>

    </form>
    <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal" style="display: none;">{{ $t('Change') }}</button>
    <MessageModal :content="modalMessage"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'
import store from "@/store";

export default {
  name: "ResetPasswordView",
  data() {
    return {
      data: {
        old_password: '',
        new_password: '',
        new_password_confirmation: '',
      },
      errorMessage: '',
      modalMessage: '',
      isLoading: false
    }
  },
  methods: {
    changeUserPassword() {
      fetch(this.serverURL + 'api/auth/reset-password', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(this.data)
      })
          .then(response => response.json())
          .then(data => {
            if (data.status === 200) {
              this.errorMessage = '';
              this.modalMessage = 'Password Changed Successfully!';
              this.$nextTick(() => {
                document.getElementById('openModal').click();
              });
            } else {
              this.errorMessage = data.message;
            }
          })
          .catch(error => {
            console.log(error.message);
          })
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  components: {
    MessageModal,
  },
};
</script>

<style>
.reset-password {
  text-align: left;
}

[data-direction = rtl] form  {
    text-align: right !important;
}
.reset-password h2 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
}

.reset-password .background h3 {
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.reset-password h2 {
  font-size: 22px;
  font-weight: bold;
  color: black;
  margin-bottom: 0;
}

/* Form */
.reset-password form label {
  display: block;
  font-size: 14px;
  color: #6F825F;
  margin: 2rem 0 1rem;
}

.reset-password form input {
  border-radius: 10px;
  background-color: #F5F5F5;
  padding: 1rem 2rem;
  font-size: 14px;
  color: black;
  width: 30rem;
}

@media (max-width: 500px) {
  .reset-password form input {
    width: 100%;
  }
}

.reset-password form input::placeholder {
  color: #A2A2A2;
}

.reset-password form input[type='email'] {
  background-color: #9B9A9A;
  color: #FFF;
}

.reset-password form input[type='email']::placeholder {
  color: #FFF;
}

.reset-password form input[type='submit'] {
  margin-top: 2rem;
  background-color: #B2CC9B;
  color: #FFF;
  font-size: 18px;
  font-weight: bold;
}

</style>