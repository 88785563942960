<template>
  <div class="product" id="product">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3 >{{displayName }}</h3>
    </div>
    <div class="container">
      <img class="heart" v-if="info.is_added_to_wishlist" src="@/assets/icons/red-heart.svg" data-value="remove" alt="Heart Icon" />
      <img class="heart" v-else src="@/assets/icons/black-heart.svg" data-value="add" alt="Heart Icon" />
      <div class="row">
        <div class="col-md-5">
          <div class="images">
            <img :src="images.length > 0?images[0].image:''" alt="Product Image">
            <div class="group">
              <div class="row">
                <template v-for="(image) in images.slice(1)" :key="image">
                  <div @click="switchImage(image?.id)" class="col-3">
                    <img :src="image.image" alt="Product Image">
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-7">
          <div class="content">
            <h2>{{ displayName }}</h2>
            <div class="prices">
              <h3>{{ info.offer_price && handleDate(info?.offer_price_end)?info.offer_price:info.price }} {{ $t('KD') }}</h3>
              <h3 v-if="info.offer_price && handleDate(info?.offer_price_end)" class="old-price">{{ info.offer_price?info.price + $t('KD'):'' }}</h3>
            </div>
            <div class="selections">
              <div class="size" v-if="info.sizes && info.sizes.length > 0">
                <h4>{{$t('Size')}}*</h4>
                <div class="box">
                  <span v-for="size in info.sizes" :data-size="size" :key="size.id" :data-id="size.id" :data-name="size.name">{{ size.translations.find(t => t.locale.includes(language))?.name }}</span>
                </div>
              </div>
              <div class="color" v-if="colors.length > 0">
                <h4>{{$t('Color')}}*</h4>
                <div class="box">
                  <span v-for="color in colors" :key="color.id" :data-color="color" :data-id="color.id" :data-name="color.name">{{ color.translations.find(t => t.locale.includes(language))?.name}}</span>
                </div>
              </div>
              <div v-if="!isOut" class="quantity">
                <h4>{{$t('Quantity')}}</h4>
                <div class="details">
                  <div class="counter">
                    <img class="button mines" src="@/assets/icons/product/mines.svg" alt="Mines Icon">
                    <span class="number">{{ quantity }}</span>
                    <img class="button plus" src="@/assets/icons/product/plus.svg" alt="Plus Icon">
                  </div>
                  <button @click="addToMyCart()">{{ $t('Add To Cart') }}</button>
                </div>
              </div>
              <div class="error-message">
                {{ errorMessage }}
              </div>
              <div v-if="isOut" class="out">
                <img src="@/assets/icons/product/out.svg" alt="Mines Icon">
                <p>{{ $t('This product is out of stock!') }}</p>
              </div>
              <div class="description">
                <h4>{{ $t('Description') }}</h4>
                <p>{{ displayDescription }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import langStor from '@/langStore'

import store from '@/store'

export default {
  name: "ProductView",
  props: {
    productName: String
  },
  data() {
    return {
      info: {},
      images: [],
      size_id: -1,
      size: '',
      color_id: -1,
      color: '',
      allColor:{},
      color_size_id: -1,
      quantity: 1,
      stock: 0,
      isOut: false,
      variant: '',
      colors: [],
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
    handleDate(value){
      const dateToCompare = new Date(value);

          // Get today's date
          const today = new Date();

          // Set the time to 00:00:00 for both dates to only compare the date part
          today.setHours(0, 0, 0, 0);
          dateToCompare.setHours(0, 0, 0, 0);

          // Compare dates
          if (dateToCompare < today) {
              return false
          } else {
            return true
      }
    },
    checkValidColors(){
      this.colors = [];
      for(let i = 0; i < this.info.product_color_size.length; i++){
        if(this.info.product_color_size[i].size.id == this.size_id){
          this.colors.push(this.info.product_color_size[i].color);
        }
      }
    },
    getColorSizeId(){
      for(let i = 0; i < this.info.product_color_size.length; i++){
        if((this.variant == 'color' && this.info.product_color_size[i].color.id == this.color_id) ||
          (this.variant == 'size' && this.info.product_color_size[i].size.id == this.size_id) ||
          (this.variant == 'both' && this.info.product_color_size[i].color.id == this.color_id &&
              this.info.product_color_size[i].size.id == this.size_id)){

          this.color_size_id = this.info.product_color_size[i].id;
          this.images = this.info.product_color_size[i].images;
          this.stock = this.info.product_color_size[i].stock;
          if(this.stock == 0)
            this.isOut = true;
          break;
        }
      }
    },
    switchImage(imageId) {
      // Find the index of the clicked image
      const index = this.images.findIndex(image => image.id === imageId);
      if (index !== -1) {
        // Swap the first image with the clicked image
        const temp = this.images[0];
        this.images[0] = this.images[index];
        this.images[index] = temp;
      }
    },
    getInfo() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/products/' + localStorage.getItem('product_id'), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200){
            this.info = data.data;
            this.images = data.data.product_color_size[0].images;
            this.colors = data.data.colors;

            if(this.info.colors.length == 0)
              this.variant = 'size';
            else if(this.info.sizes.length == 0)
              this.variant = 'color';
            else
              this.variant = 'both';
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
    addToCart(){
      fetch(this.serverURL + 'api/catalog/products/'+ localStorage.getItem('product_id') +'/cart',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify({
          session_id: localStorage.getItem('sessionId'),
          quantity: this.quantity,
          product_color_size_id: this.color_size_id,
        })
      })
        .then(response => response.json())
        .then(this.$router.push({ name: 'Cart' }))
        .catch(error => {
          console.error(error);
        })
    },
    addWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    },
    removeWishList(id) {
      fetch(this.serverURL + 'api/catalog/products/' + id +'/wishlist',{
        method:  'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            location.reload();
        })
        .catch(error => {
          console.error(error);
        })
    },
    addToMyCart(){
      console.log(store.state.userInfo==null,'>>>>>>>');
      console.log(localStorage.getItem('product_id'),'wajdi');
      if(this.variant != 'color' && this.size_id == -1){
        this.errorMessage = 'Size Is Required';
      }else if(this.variant != 'size' && this.color_id == -1){
        this.errorMessage = 'Color Is Required';
      }else if(this.quantity == 0){
        this.errorMessage = 'Quantity Must Be Greater Than 0';
      }else{
          this.errorMessage = '';

          // <<<<<<<<<<if unAuth>>>>>>>>>>>>>>>>
        if(store.state.userInfo==null){
          let LocalCartProducts = localStorage.getItem('cartProducts');
          if (LocalCartProducts) {
              LocalCartProducts = JSON.parse(LocalCartProducts);
              this.info.cart_quantity=this.quantity
              this.info.color=this.color
              this.info.size=this.size
              this.info.cart_id=this.info?.id
              this.info.stock=this.stock
              this.info.color_size_id=this.color_size_id
              LocalCartProducts.push(this.info);
              localStorage.setItem('cartProducts', JSON.stringify(LocalCartProducts));
              this.$router.push({ name: 'Cart' });
          }else {
              this.info.cart_quantity=this.quantity
              this.info.color=this.color
              this.info.size=this.size
              this.info.cart_id=this.info?.id
              this.info.color_size_id=this.color_size_id
              this.info.stock=this.stock
              const newArray = [this.info];
              console.log(newArray,'test');
              localStorage.setItem('cartProducts', JSON.stringify(newArray));
              this.$router.push({ name: 'Cart' });
          }
          // <<<<<<<<<<if Auth>>>>>>>>>>>>>>>>
        }else{
           this.addToCart()
        }
      }
    },
  },
  beforeMount() {
    this.getInfo();
  },
  mounted() {
    let that = this;

    // Heart Clicked
    window.$('.product .container .heart').on('click', function (){
      if(localStorage.getItem('token')=='' || !localStorage.getItem('token')){
        that.$router.push({ name: 'Login' });
      }
      else{
        let id = localStorage.getItem('product_id');

        if(window.$(this).data('value') == 'add'){
          that.addWishList(id);
        }else{
          that.removeWishList(id);
        }
      }
    });

    // Size Clicked
    window.$('body').on('click', '.product .content .selections .size .box span', function (){
      that.errorMessage = '';
      that.color_size_id = -1;
      that.quantity = 1;
      that.stock = 0;
      that.isOut = false;

      // Choose Size
      window.$(this).addClass('active').siblings().removeClass('active');
      that.size_id = window.$(this).data('id');
      that.size = window.$(this).data('name');
      if(that.variant == 'both'){
        // Reset Colors
        that.color_id = -1;
        that.color = '';
        window.$('.product .content .selections .color .box span').removeClass('active');
        // Check Valid Colors
        that.checkValidColors();
      }else{
        that.getColorSizeId();
      }
    });

    // Color Clicked
    window.$('body').on('click', '.product .content .selections .color .box span', function (){
      that.errorMessage = '';
      that.color_size_id = -1;
      that.quantity = 1;
      that.stock = 0;
      that.isOut = false;

      if((that.variant == 'both' && that.size_id != -1) || (that.variant != 'both')){
        window.$(this).addClass('active').siblings().removeClass('active');
        that.color_id = window.$(this).data('id');
        that.color = window.$(this).data('name');
        that.allColor = window.$(this).data('color');
        that.getColorSizeId();
      }else if(that.variant == 'both' && that.size_id == -1){
        that.errorMessage = 'Select Size First';
      }
    });

    // Mines Clicked
    window.$('body').on('click', '.product .content .selections .quantity .details .counter .mines', function (){
      if((that.variant == 'both' && that.size_id != -1 && that.color_id != -1) ||
      (that.variant == 'color' && that.color_id != -1) ||
      (that.variant == 'size' && that.size_id != -1)){
        that.errorMessage = '';
        if(that.quantity > 1)
          that.quantity -= 1;
      } else
        that.errorMessage = 'Choose Variants First!';
    });

    // Plus Clicked
    window.$('body').on('click', '.product .content .selections .quantity .details .counter .plus', function (){
      if((that.variant == 'both' && that.size_id != -1 && that.color_id != -1) ||
      (that.variant == 'color' && that.color_id != -1) ||
      (that.variant == 'size' && that.size_id != -1)){
        that.errorMessage = '';
        if(that.quantity < that.stock)
          that.quantity += 1;
      } else
        that.errorMessage = 'Choose Variants First!';
    });

    // Add To Cart Clicked
    // window.$('body').on('click', '.product .content .selections .quantity .details button', function (e){
    //   e.stopImmediatePropagation();
    //   if(that.variant != 'color' && that.size_id == -1){
    //     that.errorMessage = 'Size Is Required';
    //   }else if(that.variant != 'size' && that.color_id == -1){
    //     that.errorMessage = 'Color Is Required';
    //   }else if(that.quantity == 0){
    //     that.errorMessage = 'Quantity Must Be Greater Than 0';
    //   }else{
    //     that.errorMessage = '';
    //     console.log(localStorage.getItem('sessionId'))
    //     that.addToCart();
        
    //     that.$router.push({ name: 'Cart' });
    //   }
    // });
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    language(){
      return langStor.state.language
    },
    displayName() {
      if (!this.info?.translations || this.info.translations.length === 0) {
        return this.info?.name || '';
      }
      
      const translation = this.info.translations.find(t => t.locale.includes(this.language));
      return translation ? translation.name : this.info?.name || '';
    },
    displayDescription() {
      if (!this.info?.translations || this.info.translations.length === 0) {
        return this.info?.description || '';
      }
      
      const translation = this.info.translations.find(t => t.locale.includes(this.language));
      return translation ? translation.description : this.info?.description || '';
    },
  },
  components: {
    Loading,
  },
};
</script>

<style>
.product-image{
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
}
[data-direction = rtl] .container {
    direction: rtl !important;
}
[data-direction = rtl] .box {
    gap: 10px !important;
}
[data-direction = rtl] .details {
    gap: 10px !important;
}
[data-direction = rtl] .heart {
    left: 0 !important;
    right: unset !important
}
.product-image img{
    max-width: 100%;
    max-height: 100%;
}
/* Background */
.product .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.product .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.product .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* Container */
.product .container{
  padding-top: 2rem;
  padding-bottom: 2rem;
  position: relative;
}
.product .container .heart{
  cursor: pointer;
  background-color: #F7F6E2;
  padding: .5rem;
  border-radius: 10px;
  position: absolute;
  right: 0;
  top: 2rem;
}
/* Images */
.product .images img{
  width: 100%;
  height: 100%;
}
.product .images .group{
  margin-top: 1rem;
}

/* Content */
.product .content{
  text-align: left;
}
.product .content h2{
  font-size: 30px;
  width: 95%;
  color: black;
  font-weight: bold;
}
.product .content .prices h3{
  font-size: 26px;
}
.product .content .prices{
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 2rem 0;
}
.product .content .prices h3:first-of-type{
  color: #6F825F;
  font-weight: bold;
  margin-right: 1rem;
}
.product .content .prices h3.old-price{
  color: #A2A2A2;
  text-decoration: line-through;
}
.product .content .selections h4{
  color: #313131;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 1rem;
}
.product .content .selections .color,
.product .content .selections .description,
.product .content .selections .quantity{
  margin-top: 2rem;
}
.product .content .selections .box{
  display: flex;
  justify-content: flex-start;
  overflow: auto;
}
.product .content .selections .box span{
  color: #A2A2A2;
  font-size: 18px;
  border: 1px solid #A2A2A2;
  padding: .5rem 1rem;
  border-radius: 10px;
  cursor: pointer;
}
.product .content .selections .box span:not(:last-of-type){
  margin-right: 1rem;
}
.product .content .selections .box span.active{
  color: #FFF;
  background-color: #B2CC9B;
  border-color: #B2CC9B;
}
.product .content .selections .quantity .details{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.product .content .selections .quantity .details .counter{
  margin-right: 1rem;
  background-color: #EAF1E4;
  padding: .5rem 1rem;
  color: #6F825F;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product .content .selections .quantity .details .counter .button{
  border-radius: 50%;
  padding: .3rem;
  border: 2px solid #6F825F;
  cursor: pointer;
}
.product .content .selections .quantity .details .counter .button:first-of-type{
  padding: .58rem .3rem;
}
.product .content .selections .quantity .details .counter .number{
  font-size: 22px;
  font-weight: bold;
  margin: 0 2rem;
}
.product .content .selections .quantity .details button{
  background-color: #B2CC9B;
  border-radius: 10px;
  padding: .7rem 0;
  width: 12rem;
  color: #FFF;
  display: block;
  font-size: 16px;
  font-weight: bold;
}
.product .content .selections .out{
  margin-top: 2rem;
  background-color: #CC9B9B;
  border-radius: 10px;
  padding: 1.5rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  max-width: 20rem;
}
.product .content .selections .out img{
  margin-right: 1rem;
}
.product .content .selections .out p{
  font-size: 16px;
  font-weight: bold;
  margin: 0;
  color: #FFF;
}
.product .content .selections .description p{
  color: #444444;
  font-size: 16px;
  margin: 0;
}
@media(max-width: 767px){
  .product .container .heart{
    right: 2rem;
    top: 3rem;
  }
  .product .content{
    margin-top: 2rem;
  }
  .product .content h2{
    font-size: 20px;
  }
  .product .content .prices h3,
  .product .content .selections h4,
  .product .content .selections .quantity .details .counter .number{
    font-size: 18px;
  }
  .product .content .selections .box span{
    margin-bottom: .5rem;
  }
  .product .content .selections .quantity .details .counter .number{
    margin: 0 1rem;
  }
  .product .content .selections .quantity .details button{
    font-size: 16px;
    width: 10rem;
  }
  .product .content .selections .description p{
    font-size: 14px;
  }
}
@media(max-width: 400px){
  .product .container .heart{
    right: 1.4rem;
    top: 2.6rem;
  }
  .product .content .selections .quantity .details button{
    width: 7rem;
  }
}
</style>