<template>
  <AppHeader @open_sidebar="hideAll=true" @close_sidebar="hideAll=false" :hideAll="hideAll" />
  <router-view v-if="hideAll==false" />
  <AppFooter v-if="hideAll==false" />
</template>

<script>
import {computed, reactive } from 'vue'
import { useHead } from '@vueuse/head'

import AppHeader from '@/components/global/AppHeader.vue'
import AppFooter from '@/components/global/AppFooter.vue'
export default {
  name: 'App',
  data: function () {
    return {
      hideAll:false,
    };
  },
  methods: {
  },
  beforeMount () {
  },
  mounted () {},
  components: {
    AppHeader,
    AppFooter,
  },
  setup() {
    
    const siteData = reactive({
      title: `Tiny Koala`,
      description: ``,
    })
    useHead({
      // Can be static or computed
      title: computed(() => siteData.title),
      meta: [
        {
          name: `description`,
          content: computed(() => siteData.description),
        },
        ],
     
    })
  }
}
</script>

<style>
@font-face {
  font-family: neosans-Regular;
  src: url(@/assets/fonts/Neo\ Sans/NeoSansProRegular.OTF);
}
@font-face {
  font-family: cairo-Regular;
  src: url(@/assets/fonts/Cairo/Cairo-Regular.ttf);
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
}
.ar *{
  direction: rtl;
}
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  font-family: neosans-Regular;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #FFF;
}
#app.ar{
  font-family: cairo-Regular;
}
.container-fluid{
  max-width: 95%;
}
.vl-overlay{
  position: fixed !important;
}
.error-message{
  margin-top: 1rem;
  text-align: center;
  font-weight: bold;
  color: red;
}
.error-message p{
  margin-bottom: 0;
}
</style>
