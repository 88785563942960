<template>
  <div class="signup" id="signup">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('Signup') }}</h3>
    </div>
    <div class="container">
      <div class="form">
        <img src="@/assets/icons/signup/signup.svg" alt="Signup Icon">
        <h1>{{ $t('Create') }}<br />{{ $t('Your Account') }}</h1>
        <form @submit.prevent="register">
          <label>{{ $t('Full Name') }}*</label>
          <input type="text" v-model="signupData.name" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Email Address') }}*</label>
          <input type="email" v-model="signupData.email" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Mobile Number') }}*</label>
          <input type="text" pattern="[0-9]+" v-model="signupData.mobile" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Confirm Mobile Number') }}*</label>
          <input type="text" pattern="[0-9]+" v-model="signupData.mobile_confirmation" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Password') }}*</label>
          <input type="password" v-model="signupData.password" :placeholder="$t('Enter Here')" required>
          <label>{{ $t('Confirm Password') }}*</label>
          <input type="password" v-model="signupData.password_confirmation" :placeholder="$t('Enter Here')" required>
          <p class="conditions">{{ $t('By Signing up you accept all') }} <a href="/Terms">{{ $t('terms & conditions') }}</a>  {{ $t('of') }}<span class="conditions-span">{{ $t('Tiny Koala') }}</span>!</p>
          <input class="submit-btn" type="submit" value="Register" :disabled="isLoading ? true:false">
          <div class="error-message">
            <p v-for="error in errorMessages" :key="error">{{ error }}</p>
          </div>
        </form>
        <p class="login-text">{{ $t('Already Have An Account?') }}</p>
        <router-link class="login-btn" to="/Login">{{ $t('Login') }}</router-link>        
        <button id="openModal" data-bs-toggle="modal" data-bs-target="#messageModal" style="display: none;"></button>
      </div>
    </div>

    <message-modal content="Account created successfully!"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'
import store from '@/store'

export default {
  name: "SignupView",
  data() {
    return {
      signupData: {
        name: '',
        email: '',
        mobile: '',
        mobile_confirmation: '',
        password: '',
        password_confirmation: '',
      },
      errorMessages: [],
      isLoading: false,
      carts:[],
    }
  },
  methods: {
    register() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/auth/register',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          name: this.signupData.name,
          email: this.signupData.email,
          phone: this.signupData.mobile,
          phone_confirmation: this.signupData.mobile_confirmation,
          password: this.signupData.password,
          password_confirmation: this.signupData.password_confirmation,
          session_id: localStorage.getItem('sessionId'),
        })
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;

          if (data.status == 201) {
            localStorage.setItem('token', data.token);
            store.state.accessToken = localStorage.getItem('token');

            localStorage.setItem('user_info', JSON.stringify(data.data));
            store.state.userInfo = JSON.parse(localStorage.getItem('user_info'));
            
            let randomNumberString = Math.random().toString(36).substring(2, 10);

            const date = new Date();
            let currentTimeStamp = date.getTime().toString();

            localStorage.setItem('sessionId', randomNumberString + currentTimeStamp);

            window.$('.signup #openModal').click();
          }else{
            let messages = [];

            for(let propt in data.errors){
              messages.push(data.errors[propt].join(' '));
            }
            this.errorMessages = messages;
          }
          // send cart to backend
          const localCart = localStorage.getItem('cartProducts');
            if(localCart){
              this.carts=JSON.parse(localCart);
              for(let i = 0; i < this.carts.length; i++){
                this.totalPrice += (this.carts[i]?.offer_price?this.carts[i].offer_price:this.carts[i].price) * parseInt(this.carts[i]?.cart_quantity);
                console.log(this.carts[i].offer_price,this.carts[i].price,parseInt(this.carts[i]?.cart_quantity),'1');
              }
              this.addToCart(this.carts)
            }
        })
        .catch(error => {
          console.error(error);
        })
    },
    addToCart(carts) {
      let data = {
        items: []
      };
      for(let i = 0; i < carts.length; i++){
        data.items.push({
          product_color_size_id: carts[i].color_size_id,
          quantity: carts[i].quantity
        });
      }

      fetch(this.serverURL + 'api/catalog/cart/bulk-add',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => { 
          if(data.status == 200)
            localStorage.setItem('myCarts', JSON.stringify([]));
        })
        .catch(error => {
          console.error(error);
        })
    }
  },
  mounted() {
    let that = this;

    // Ok Clicked
    window.$('.signup .modal .ok-btn').on('click', function (){
      that.$router.push({ name: "Home"});
    });
  },
  components: {
    MessageModal,
  },
};
</script>

<style>
/* Background */
.signup .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.signup .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.signup .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
[data-direction = rtl] label  {
    text-align: right
}

/* Form */
.signup .form{
  padding: 4rem 0 2rem;
  width: 50%;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 992px){
  .signup .form{
    width: 60%;
  }
}
@media (max-width: 767px){
  .signup .form{
    width: 70%;
  }
}
@media (max-width: 400px){
  .signup .form{
    width: 80%;
  }
}
@media (max-width: 300px){
  .signup .form{
    width: 90%;
  }
}
.signup .form h1{
  font-size: 32px;
  font-weight: bold;
  color: #6F825F;
  margin: 2rem 0;
}
.signup .form label{
  color: #6F825F;
  font-size: 14px;
  display: block;
  margin: .5rem 0;
}
.signup .form label:not(:first-of-type){
  margin-top: 2rem;
}
.signup .form input{
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  outline: none;
  padding: .8rem 2rem;
  border-radius: 10px;
}
.signup .form input::placeholder{
  color: #A2A2A2;
  font-size: 14px;
  font-weight: lighter;
}
.signup .form .conditions{
  color: #6F825F;
  font-size: 12px;
  text-align: left;
  margin: 2rem 0 0 0;
}
.signup .form .conditions-span{
  color: #6F825F;
  font-weight: bold;
}

.signup .form .conditions a{
  color: #6F825F;
  font-weight: bold;
}

.signup .form .submit-btn{
  background-color: #B2CC9B;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
}
.signup .form .login-text{
  color: black;
  font-size: 16px;
  margin-top: 2rem;
  text-align: center;
}
.signup .form .login-btn{
  display: block;
  font-size: 18px;
  font-weight: bold;
  background-color: #EAF1E4;
  padding: .8rem 2rem;
  border-radius: 10px;
  text-align: center;
  text-decoration: none;
  color: #6F825F;
}
</style>