<template>
  <div class="checkout" id="checkout">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <div class="background">
      <div class="overlay"></div>
      <h3>{{ $t('CHECKOUT') }}</h3>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-8">
          <div class="address">
            <h3>{{ $t('DELIVERY ADDRESS') }}</h3>
            <button data-bs-toggle="modal" data-bs-target="#addAddressModal">{{ $t('Add New Address') }}</button>
            <div v-if="addresses.length > 0" class="cards">
              <div class="row">
                <div class="col-md-6" v-for="adress in addresses" :key="adress.id">
                  <div class="card" :data-id="adress.id" :data-charge="adress.area?adress.area.deliver_charge:'0'">
                    <div class="heading">
                      <h4>{{ adress.title }}</h4>
                      <input type="radio" v-model="addressId" :value="adress.id" />
                    </div>
                    <span>{{ adress.area?adress.area.name:'' }}</span>
                    <p>{{ $t('Block') }} {{ adress.block }}, {{ $t('Street') }} {{ adress.street }}, {{ $t('Avenue') }} {{ adress.avenue }}.</p>
                  </div>
                </div>
              </div>
            </div>
            <div v-else class="no-data">
              <img src="@/assets/icons/empty-logo.svg" alt="Empty Logo" />
              <p>{{ $t('No Saved Address') }}.<br/>{{ $t('Please Save New Address!') }}</p>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="payement">
            <div class="code">
              <h3>{{ $t('Promo Code') }}</h3>
              <p>{{ $t('Have a Promo Code? Use it here') }}</p>
              <div class="input">
                <input type="text" v-model="promoCode" placeholder="Enter Here" />
                <button v-if="messageState == 0" class="apply">{{ $t('Apply') }}</button>
                <button v-else class="remove">{{ $t('Remove') }}</button>
              </div>
              <div v-if="messageState == 1" class="message ok-message">
                <img src="@/assets/icons/checkout/ok.svg" alt="">
                <p>{{ $t('Code Applied Successfully!') }}</p>
              </div>
              <div v-if="messageState == 2" class="message error-message">
                <img src="@/assets/icons/checkout/error.svg" alt="">
                <p>{{ $t('Invalid promo code!') }}</p>
              </div>
            </div>
            <div class="methods">
              <h3>{{ $t('Payment Method') }}</h3>
              <div class="method">
                <div class="icon">
                  <img src="@/assets/icons/checkout/knet.svg" alt="Knet Icon">
                  <span>{{ $t('KNET') }}</span>
                </div>
                <input type="radio" v-model="paymentMethod" value="kn" />
              </div>
              <hr />
              <div class="method">
                <div class="icon">
                  <img src="@/assets/icons/checkout/credit.svg" alt="Credit Icon">
                  <span>{{ $t('Credit') }}</span>
                </div>
                <input type="radio" v-model="paymentMethod" value="vm" />
              </div>
            </div>
            <div class="amount">
              <div class="item">
                <span>{{ $t('Products Total') }}</span>
                <span>{{ productsAmount }} {{ $t('KD') }}</span>
              </div>
              <div class="item">
                <span>{{ $t('Discount') }}</span>
                <span>{{ discountedAmount }} {{ $t('KD') }}</span>
              </div>
              <div class="item">
                <span>{{ $t('Delivery Charges') }}</span>
                <span>{{ deliveryCharges }} {{ $t('KD') }}</span>
              </div>
              <div class="item total">
                <span>{{ $t('Total:') }}</span>
                <span>{{ totalAmount }} {{ $t('KD') }}</span>
              </div>
              <a>{{ $t('Proceed To Pay') }}</a>
            </div>
            <div class="error-message">
              {{ errorMessage }}
            </div>
            <div class="error-message">
              {{ check_error }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <add-address-modal/>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

import AddAddressModal from '@/components/Address/AddAddressModal.vue'

export default {
  name: "CheckoutView",
  data() {
    return {
      addresses: [],
      addressId: -1,
      promoCode: '',
      messageState: 0,
      paymentMethod: '',
      productsAmount: 0,
      discountedAmount: 0,
      check_error:'',
      deliveryCharges: 0,
      totalAmount: 0,
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
    getAddresses() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/my-addresses', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200)
            this.addresses = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    applyPromo(){
      this.isLoading = true;

      fetch(this.serverURL + 'api/cart/apply-promo-code',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify({
          code: this.promoCode,
        })
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200){
            this.discountedAmount = this.totalAmount - data.data.new_total_price;
            this.totalAmount = (data.data.new_total_price != 0?data.data.new_total_price:this.productsAmount) + this.deliveryCharges;
            this.messageState = 1;
          }else{
            this.messageState = 2;
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
    checkout(){
      this.isLoading = true;
      this.check_error=''
      let data = {};
      data['address_id'] = this.addressId;
      data['payment_method'] = this.paymentMethod;
      if(this.promoCode != '')
        data['promo_code'] = this.promoCode;

      fetch(this.serverURL + 'api/cart/checkout',{
        method:  'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${store.state.accessToken}`,
        },
        body: JSON.stringify(data)
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 201){
            localStorage.removeItem('products_total');
            this.$router.push({ name:'Cart' });
            window.open(data.data.payment_url);
          }else{
            console.log(data,'<><><><><');
            this.check_error=data.message
          }
        })
        .catch(error => {
          console.error(error);
        })
    },
  },
  beforeMount() {
    this.getAddresses();

    if (localStorage.getItem('products_total')){
      this.productsAmount = parseInt(localStorage.getItem('products_total'));
    }
    this.totalAmount = this.productsAmount;
  },
  mounted() {
    let that = this;

    // On Toggle Modal
    window.$( '#addAddressModal')
      .on('hide.bs.modal', function() {
          window.$('html').css('overflow', 'initial');
      })
      .on('show.bs.modal', function() {
        window.$('html').css('overflow', 'hidden');
      });

    // On Apply Button Clicked
    window.$('body').on('click', '.checkout .payement .code .input button.apply', function (){
      if(that.promoCode != ''){
        that.applyPromo();
      }
    });

    // On Remove Button Clicked
    window.$('body').on('click', '.checkout .payement .code .input button.remove', function (){
      that.messageState = 0;
      that.promoCode = '';
      that.discountedAmount = 0;
      that.totalAmount = that.productsAmount + that.deliveryCharges;
    });

    // On Proceed To Pay Clicked
    window.$('body').on('click', '.checkout .payement .amount a', function (){
      if(that.addressId == -1){
        that.errorMessage = 'You Must Select An Address';
      }else if(that.paymentMethod == ''){
        that.errorMessage = 'You Must Select The Payment\'s Type';
      }else{
        that.errorMessage = '';
        that.checkout();
      }
    });
  },
  watch: {
    addressId(){
      for(let i = 0; i < this.addresses.length; i++){
        if(this.addresses[i].id == this.addressId){
          this.deliveryCharges = parseInt(this.addresses[i].area.deliver_charge);
          this.totalAmount = (this.discountedAmount != 0?this.discountedAmount:this.productsAmount) + this.deliveryCharges;
          break;
        }
      }
    },
  },
  components: {
    Loading,
    AddAddressModal,
  },
};
</script>

<style>
.checkout{
  text-align: left;
}
[data-direction = rtl] .checkout{
    text-align: right !important;
}
/* Background */
.checkout .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.checkout .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.checkout .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
.checkout .container{
  margin-top: 2rem;
  margin-bottom: 2rem;
}

/* Cards */
.checkout .cards{
  margin-top: 2rem;
}
.checkout .cards .card{
  border: 1px solid #B2CC9B;
  padding: 2rem;
  height: 90%;
  margin-bottom: 2rem;
}
.checkout .cards .card .heading{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: .5rem;
}
.checkout .cards .card .heading h4{
  font-size: 18px;
  font-weight: bold;
  color: #6F825F;
  margin-bottom: 0;
}
.checkout .cards .card .heading input{
  accent-color: #B2CC9B;
}
.checkout .cards .card span,
.checkout .cards .card p{
  color: #444444;
  font-size: 14px;
  margin-bottom: .5rem;
}
.checkout .cards .card p{
  margin-bottom: 0;
}

/* Address */
.checkout .address h3{
  font-size: 22px;
  font-weight: bold;
  color: #1E181A;
  margin-bottom: 1rem;
}
.checkout .address button{
  color: #FFF;
  background-color: #B2CC9B;
  border-radius: 10px;
  font-size: 18px;
  font-weight: bold;
  padding: .5rem 2rem;
}

/* No Data */
.checkout .address .no-data{
  background-color: #F8F8F8;
  border-radius: 10px;
  width: 29rem;
  padding: 2rem;
  margin-top: 2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.checkout .address .no-data img{
  width: 4.5rem;
  margin-right: 2rem;
}
.checkout .address .no-data p{
  font-size: 18px;
  color: black;
  margin-bottom: 0;
}

/* Payement */
.checkout .payement .code h3,
.checkout .payement .methods h3{
  font-size: 20px;
  font-weight: bold;
  color: #1E181A;
  margin-bottom: .5rem;
}
.checkout .payement .code p{
  font-size: 16px;
  color: #1E181A;
  margin-bottom: 1rem;
}
.checkout .payement .code .input{
  display: flex;
  padding: .5rem .5rem .5rem 1rem;
  background-color: #F5F5F5;
  border-radius: 10px;
}
.checkout .payement .code .input input,
.checkout .payement .code .input input::placeholder{
  font-size: 14px;
  color: black;
  margin-right: .5rem;
}
.checkout .payement .code .input input{
  background-color: transparent;
  width: 100%;
}
.checkout .payement .code .input button{
  background-color: #B2CC9B;
  border-radius: 10px;
  color: #FFF;
  padding: .6rem 2rem;
}
.checkout .payement .code .message{
  display: flex;
  justify-content: flex-start;
  margin-top: 1rem;
}
.checkout .payement .code .message img{
  margin-right: .5rem;
}
.checkout .payement .code .message p{
  margin-bottom: 0;
  font-size: 14px;
}
.checkout .payement .code .message.ok-message p{
  color: #B2CC9B;
}
.checkout .payement .code .message.error-message p{
  color: #CC9B9B;
}
.checkout .payement .methods{
  margin-top: 2rem;
}
.checkout .payement .methods h3{
  margin-bottom: 1rem;
}
.checkout .payement .methods .method{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout .payement .methods .method .icon{
  display: flex;
  align-items: center;
}
.checkout .payement .methods .method .icon img{
  margin-right: 1rem;
}
.checkout .payement .methods .method .icon span{
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.checkout .payement .methods .method input{
  accent-color: #B2CC9B;
}
.checkout .payement .methods hr{
  background-color: #9B9A9A;
  opacity: 1;
  border: none;
  height: 1px;
  margin: 1rem 0;
  width: 100%;
}
.checkout .payement .amount{
  background-color: #F7F6E2;
  border-radius: 10px;
  padding: 2rem;
  margin-top: 2rem;
}
.checkout .payement .amount span{
  color: #6F825F;
  font-size: 18px;
}
.checkout .payement .amount .total span{
  font-weight: bold;
}
.checkout .payement .amount .item{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}
.checkout .payement .amount a{
  cursor: pointer;
  text-decoration: none;
  background-color: #B2CC9B;
  border-radius: 10px;
  color: #FFF;
  width: 100%;
  display: block;
  padding: .8rem .5rem;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

@media(max-width: 767px){
  .checkout .payement{
    margin-top: 2rem;
  }
  .checkout .address .no-data{
    width: 100%;
  }
}
</style>