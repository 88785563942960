<template>
  <div class="account-orders" id="account-orders">
    <Loading v-model:active="isLoading"
      :can-cancel="true"
      :is-full-page="false"
      color="#B2CC9B"
      loader="bars"
      background-color="#000"
      :lock-scroll="false"
    />
    <h2>{{ $t('Orders') }}</h2>
    <table v-if="orders.length > 0">
      <thead>
        <tr>
          <th scope="col">{{ $t('Date') }}</th>
          <th scope="col">{{ $t('Order Id') }}</th>
          <th scope="col">{{ $t('Items') }}</th>
          <th scope="col">{{ $t('Price') }}</th>
          <th scope="col">{{ $t('Status') }}</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr class="record" v-for="order in orders" :key="order.id" :data-id="order.id">
          <td data-label="Date">{{ order.created_at?order.created_at.substring(0, 10):'' }}</td>
          <td data-label="Order Id">{{ order.id }}</td>
          <td data-label="Items">{{ order.products?order.products.length:'' }}</td>
          <td data-label="Price">{{ order.total_amount }} {{ $t('KD') }}</td>
          <td data-label="Status"><span class="status">{{ order.status }}</span></td>
          <td data-label=""><button class="view">{{ $t('View') }}</button></td>
        </tr>
      </tbody>
    </table>
    <div v-else class="no-data">
      <img src="@/assets/icons/empty-logo.svg" alt="Empty Icon">
      <h3>{{ $t('No Order History') }}</h3>
      <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's</p>
    </div>
  </div>
</template>

<script>
// Loading
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import store from '@/store'

export default {
  name: "OrdersSection",
  data() {
    return {
      orders: [],
      isLoading: false
    }
  },
  methods: {
    getOrders() {
      this.isLoading = true;

      fetch(this.serverURL + 'api/catalog/my-orders', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          this.isLoading = false;
          if(data.status == 200){
            this.orders = data.data;
          }
        })
        .catch(error => {
          console.log(error);
        })
    },
  },
  beforeMount() {
    this.getOrders();
  },
  mounted() {
    let that = this;

    // View Clicked
    window.$('body').on('click', '.account-orders table .view', function (){
      let fullPath = '/OrderDetails/' + window.$(this).parents('.record').data('id');
      that.$router.push({ path: fullPath });
    });
  },
  components: {
    Loading,
  },
};
</script>

<style scoped>
.account-orders h2{
  font-size: 22px;
  font-weight: bold;
  color: black;
  text-align: left;
}
[data-direction = rtl] h2  {
    text-align: right !important;
}

/* No Data */
.account-orders .no-data{
  background-color: #F7F6E2;
  width: 29rem;
  margin: 8vh auto 0;
  border-radius: 10px;
  padding: 2rem;
  text-align: center;
}
@media (max-width: 500px){
  .account-orders .no-data{
    width: 90%;
  }
}

/* Table */
.account-orders table {
  border-collapse: collapse;
  width: 100%;
}
.account-orders table tr {
  background-color: #FFF;
  border-bottom: 1px solid #9B9A9A;
}
.account-orders table th,
.account-orders table td {
  text-align: left;
  color: black;
}
.account-orders table th {
  padding: .625em 0;
  font-size: 14px;
  text-transform: capitalize;
}
.account-orders table td{
  font-size: 16px;
  padding: 1.5rem 0;
}
.account-orders table .status{
  font-weight: bold;
}
.account-orders table .view{
  background-color: #B2CC9B;
  border-radius: 10px;
  color: #FFF;
  padding: .5rem;
  width: 100%;
}
@media(max-width: 500px) {
  .account-orders table {
    border: 0;
    box-shadow: none;
  }
  .account-orders table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  .account-orders table tr {
    padding: .35em;
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: .625em;
    box-shadow: 0 1px 5px #CCC;
  }
  .account-orders table tbody td {
    border-bottom: 1px solid #ddd;
    align-items: center;
    display: flex;
    justify-content: space-between;
    font-size: .8em;
  }
  .account-orders table tbody td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
  }
  .account-orders table tbody td:last-child {
    border-bottom: 0;
  }
}

/* No Data */
.account-orders .no-data h3{
  color: #6F825F;
  font-size: 20px;
  font-weight: bold;
  margin: 1rem 0;
}
.account-orders .no-data p{
  color: #6F825F;
  font-size: 16px;
}
</style>