import { reactive } from 'vue'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

const store = ({
  state: reactive({
    language: 'en',
  }),
  getters: {
    getSessionId() {
      return store.state.language
    }
  },
  mutations: {
  },
  actions: {
    setLanguage(lang){
        store.state.language = lang;
    },
  },
  plugins: [vuexLocal.plugin]
})

export default store