<template>
  <div class="forget-password" id="forget-password">
    <div class="background">
      <div class="overlay"></div>
      <h3>{{$t('Forget Your Password')}}</h3>
    </div>
    <div class="container">
      <div class="form">
        <img src="@/assets/icons/forgetPassword/lock.svg" alt="Forget Password Icon">
        <h1>{{$t('Forget')}}<br />{{$t('Your Password')}}</h1>
        <form>
          <p class="text">{{$t('Please Enter your email address which you registered Here')}}</p>
          <label>{{$t('Email Address')}}*</label>
          <input type="text" v-model="data.email" :placeholder="$t('Enter Here')" required>
          <input class="submit-btn" type="submit" :value="$t('Send')" :disabled="isLoading ? true:false">
          <p class="error-message">{{ errorMessage }}</p>
        </form>
        <button data-bs-toggle="modal" data-bs-target="#messageModal">{{$t('Test')}}</button>
      </div>
    </div>

    <message-modal content="Reset Password Link Has Been successfully Sent On Your entered email address!"/>
  </div>
</template>

<script>
import MessageModal from '@/components/global/MessageModal.vue'

export default {
  name: "ForgetPasswordView",
  data() {
    return {
      data: {
        email: '',
      },
      errorMessage: '',
      isLoading: false
    }
  },
  methods: {
  },
  beforeMount() {},
  mounted() {},
  components: {
    MessageModal,
  },
};
</script>

<style>
/* Background */
.forget-password .background{
  background-image: url('@/assets/images/background.png');
  background-size: cover;
  position: relative;
  height: 10vh;
}
.forget-password .background .overlay{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: .74;
  background-color: #F7F6E2;
  z-index: 1;
}
.forget-password .background h3{
  margin: 0;
  position: absolute;
  z-index: 2;
  color: black;
  font-size: 22px;
  font-weight: bold;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

/* Form */
.forget-password .form{
  padding: 4rem 0 2rem;
  width: 50%;
  margin: 0 auto;
  text-align: left;
}
@media (max-width: 992px){
  .forget-password .form{
    width: 60%;
  }
}
@media (max-width: 767px){
  .forget-password .form{
    width: 70%;
  }
}
@media (max-width: 400px){
  .forget-password .form{
    width: 80%;
  }
}
@media (max-width: 300px){
  .forget-password .form{
    width: 90%;
  }
}
.forget-password .form h1{
  font-size: 32px;
  font-weight: bold;
  color: #6F825F;
  margin: 2rem 0;
}
.forget-password .form label{
  color: #6F825F;
  font-size: 14px;
  display: block;
  margin: .5rem 0;
}
.forget-password .form label{
  margin-top: 2rem;
}
.forget-password .form input{
  width: 100%;
  background-color: #F5F5F5;
  border: none;
  outline: none;
  padding: .8rem 2rem;
  border-radius: 10px;
}
.forget-password .form input::placeholder{
  color: #A2A2A2;
  font-size: 14px;
  font-weight: lighter;
}
.forget-password .form .text{
  display: block;
  text-decoration: none;
  color: black;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.forget-password .form .submit-btn{
  background-color: #B2CC9B;
  font-size: 18px;
  font-weight: bold;
  margin-top: 2rem;
}
</style>