import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import Login from '@/views/LoginView.vue'
import Signup from '@/views/SignupView.vue'
import ForgetPassword from '@/views/ForgetPasswordView.vue'
import ResetPassword from '@/views/ResetPasswordView.vue'
import NotFound from '@/views/NotFoundView.vue'
import Category from '@/views/CategoryView.vue'
import Products from '@/views/ProductsView.vue'
import Product from '@/views/ProductView.vue'
import SearchResults from '@/views/SearchResultsView.vue'
import Cart from '@/views/CartView.vue'
import MyAccount from '@/views/MyAccountView.vue'
import Checkout from '@/views/CheckoutView.vue'
import AboutUs from '@/views/AboutUsView.vue'
import ContactUs from '@/views/ContactUsView.vue'
import Terms from '@/views/TermsView.vue'
import PrivacyPolicy from '@/views/PrivacyPolicyView.vue'
import ReturnPolicy from '@/views/ReturnPolicyView.vue'
import OrderDetails from '@/views/OrderDetailsView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: { requiresGuest: true }
  },
  {
    path: '/Signup',
    name: 'Signup',
    component: Signup,
    meta: { requiresGuest: true }
  },
  {
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword
  },
  {
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword
  },
  {
    path: '/Category/:categoryName',
    name: 'Category',
    component: Category,
    props: true
  },
  {
    path: '/Products/:titleName',
    name: 'Products',
    component: Products,
    props: true
  },
  {
    path: '/Product/:id',
    name: 'Product',
    component: Product,
    props: true
  },
  {
    path: '/SearchResults/:query',
    name: 'SearchResults',
    component: SearchResults,
    props: true
  },
  {
    path: '/Cart',
    name: 'Cart',
    component: Cart
  },
  {
    path: '/MyAccount',
    name: 'MyAccount',
    component: MyAccount
  },
  {
    path: '/Checkout',
    name: 'Checkout',
    component: Checkout
  },
  {
    path: '/AboutUs',
    name: 'AboutUs',
    component: AboutUs
  },
  {
    path: '/ContactUs',
    name: 'ContactUs',
    component: ContactUs
  },
  {
    path: '/Terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/PrivacyPolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '/ReturnPolicy',
    name: 'ReturnPolicy',
    component: ReturnPolicy
  },
  {
    path: '/OrderDetails/:id',
    name: 'OrderDetails',
    component: OrderDetails,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: NotFound
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})


router.beforeEach((to, from, next) => {
  const guestRequired = (to?.meta?.requiresGuest == true);
	const loggedIn = localStorage.getItem('token') != null;
    if (guestRequired && loggedIn) {
        next({name: 'Home'});
    }else {
        // if(have_permission)
        //     next();
        // else
        //     next({name: 'dashboard'});
        next()
    }
});

export default router
