<template>
  <div class="header" id="header">
    <div class="top-bar">
      <div class="container">
        <span>{{ $t('Follow us') }}:</span>
        <div class="social-media">
          <a target="blank" :href="contacts.instagram"><img src="@/assets/icons/header/instagram.svg" alt="Instagram Icon"></a>
          <a target="blank" :href="contacts.facebook"><img src="@/assets/icons/header/facebook.svg" alt="facebook Icon"></a>
          <a target="blank" :href="contacts.x"><img src="@/assets/icons/header/x.svg" alt="x Icon"></a>
        </div>
      </div>
    </div>
    <div class="middle-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <div class="search">
              <input type="search"  @input="debounce(() => { searchValue=$event.target.value; } , 1000);" :placeholder="$t('Search')" />
            </div>
          </div>
          <div class="col-md-4">
            <router-link to="/">
              <img class="logo" src="@/assets/logo.svg" alt="Logo">
            </router-link>
          </div>
          <div class="col-md-4">
            <div class="settings">
              <div v-if="token == ''" class="item">
                <img src="@/assets/icons/header/user.svg" alt="">
                <router-link to="/Login">{{ $t('Login') }}</router-link>
              </div>
              <div v-else class="item">
                <img src="@/assets/icons/header/user.svg" alt="">
                <router-link to="/MyAccount">{{ $t('My Account') }}</router-link>
              </div>
              <div class="item">
                <img src="@/assets/icons/header/cart.svg" alt="">
                <router-link to="/Cart">{{ $t('Cart') }}</router-link>
              </div>
              <div class="item">
                <img src="@/assets/icons/header/lang.svg" alt="">
                <a @click="selected_language='ar'" v-if="language=='en'" href="#">{{ $t('Arabic') }}</a>
                <a @click="selected_language='en'" v-if="language=='ar'" href="#">{{ $t('English') }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navbar">
      <ul>
        <li class="active"><router-link to="/">{{ $t('Home') }}</router-link></li>
        <li>
          <div class="dropdown">
            <span>{{ $t('Categories') }}<v-icon icon="mdi-chevron-down" /></span>
            <div class="dropdown-content">
              <a v-for="category in categories" :key="category.id" :data-id="category.id" :data-name="category.name">{{ category?.translations[0]?.locale.includes(language)?category?.translations[0]?.name:category?.translations[1]?.locale.includes(language)?category?.translations[1]?.name:category?.name }}</a>
            </div>
          </div>
        </li>
        <li><router-link to="/AboutUs">{{ $t('About us') }}</router-link></li>
        <li><router-link to="/ContactUs">{{ $t('Contact us') }}</router-link></li>
      </ul>
    </div>
  </div>
  <div  class="mobile_nav">
      <div class="mobile_header">
      <div v-if="openSide==false" class="item">
        <svg @click="$emit('open_sidebar');openSide=true" width="24" height="18" viewBox="0 0 24 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M22 1.05005H2C1.66848 1.05005 1.35054 1.18174 1.11612 1.41617C0.881696 1.65059 0.75 1.96853 0.75 2.30005C0.75 2.63157 0.881696 2.94951 1.11612 3.18393C1.35054 3.41835 1.66848 3.55005 2 3.55005H22C22.3315 3.55005 22.6495 3.41835 22.8839 3.18393C23.1183 2.94951 23.25 2.63157 23.25 2.30005C23.25 1.96853 23.1183 1.65059 22.8839 1.41617C22.6495 1.18174 22.3315 1.05005 22 1.05005Z" fill="#B2CC9B" stroke="#B2CC9B" stroke-width="0.5"/>
          <path d="M15.3333 7.7168H2C1.66848 7.7168 1.35054 7.84849 1.11612 8.08291C0.881696 8.31733 0.75 8.63528 0.75 8.9668C0.75 9.29832 0.881696 9.61626 1.11612 9.85068C1.35054 10.0851 1.66848 10.2168 2 10.2168H15.3333C15.6649 10.2168 15.9828 10.0851 16.2172 9.85068C16.4516 9.61626 16.5833 9.29832 16.5833 8.9668C16.5833 8.63528 16.4516 8.31733 16.2172 8.08291C15.9828 7.84849 15.6649 7.7168 15.3333 7.7168Z" fill="#B2CC9B" stroke="#B2CC9B" stroke-width="0.5"/>
          <path d="M10.8839 16.5172L10.7071 16.3404L10.8839 16.5172C11.1183 16.2828 11.25 15.9648 11.25 15.6333C11.25 15.3018 11.1183 14.9838 10.8839 14.7494L10.7071 14.9262L10.8839 14.7494C10.6495 14.515 10.3315 14.3833 10 14.3833H2C1.66848 14.3833 1.35054 14.515 1.11612 14.7494C0.881696 14.9838 0.75 15.3018 0.75 15.6333C0.75 15.9648 0.881696 16.2828 1.11612 16.5172C1.35054 16.7516 1.66848 16.8833 2 16.8833H10C10.3315 16.8833 10.6495 16.7516 10.8839 16.5172Z" fill="#B2CC9B" stroke="#B2CC9B" stroke-width="0.5"/>
        </svg>
      </div>
      <div v-if="openSide==true" @click="$emit('close_sidebar')">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_34_1781)">
        <path d="M19.9145 21.8621C20.4539 21.8934 20.9843 21.7141 21.394 21.362C22.202 20.5492 22.202 19.2365 21.394 18.4237L3.70212 0.731744C2.86176 -0.0546097 1.5431 -0.0108967 0.756742 0.829464C0.0456696 1.5894 0.00422096 2.75743 0.65971 3.56582L18.4558 21.362C18.8602 21.709 19.3822 21.888 19.9145 21.8621Z" fill="#B2CC9B"/>
        <path d="M2.24337 21.8621C2.79002 21.8598 3.31396 21.6428 3.7021 21.2578L21.3941 3.56579C22.1426 2.69167 22.0408 1.37616 21.1667 0.627552C20.3865 -0.0405642 19.2359 -0.0405642 18.4558 0.627552L0.659683 18.3195C-0.180471 19.1061 -0.223909 20.4248 0.562649 21.265C0.593943 21.2984 0.626263 21.3307 0.659683 21.362C1.09544 21.7409 1.66893 21.922 2.24337 21.8621Z" fill="#B2CC9B"/>
        </g>
        <defs>
        <clipPath id="clip0_34_1781">
        <rect width="22" height="22" fill="white" transform="matrix(-1 0 0 1 22 0)"/>
        </clipPath>
        </defs>
        </svg>
      </div>
      <div  class="item">
        <router-link to="/">
          <img class="logo" src="@/assets/logo.svg" alt="Logo" :style="openSide==true ? { marginRight: '-1rem !important' , marginLeft: '-20px !important' } : {}">
        </router-link>
      </div>
      <div v-if="openSide==false" class="item mobile_cart">
        <img src="@/assets/icons/header/cart.svg" alt="">
        <router-link to="/Cart">{{ $t('Cart') }}</router-link>
      </div>
      <div v-if="openSide==true" class="item">
      </div>
    </div>
    <div v-if="openSide==false" class="search">
      <input type="search"   @input="debounce(() => { searchValue=$event.target.value; } , 1000);" :placeholder="$t('Search')" />
    </div>
  </div>
  <div v-if="openSide==true" class="sidebar">
    <span  @click="$emit('close_sidebar')"><router-link class="active2" to="/">{{ $t('Home') }}</router-link></span>
    <div class="dropdown">
      <span class="listItem">{{ $t('Categories') }}<v-icon icon="mdi-chevron-down" /></span>
      <div class="dropdown-content">
        <a  v-for="category in categories" :key="category.id" :data-id="category.id" :data-name="category.name">{{ category?.translations[0]?.locale.includes(language)?category?.translations[0]?.name:category?.translations[1]?.locale.includes(language)?category?.translations[1]?.name:category?.name }}</a>
      </div>
    </div>
    <span  @click="$emit('close_sidebar')"><router-link router-link class="listItem" to="/AboutUs">{{ $t('About us') }}</router-link></span>
    <span  @click="$emit('close_sidebar')"><router-link router-link class="listItem" to="/ContactUs">{{ $t('Contact us') }}</router-link></span>
    <div  @click="$emit('close_sidebar')" v-if="token == ''">
        <img src="@/assets/icons/header/user.svg" alt="" :style="language=='en' ? { marginRight: '5px !important' } : {marginLeft: '5px !important'}">
        <router-link class="listItem" to="/Login">{{ $t('Login') }}</router-link>
      </div>
      <div  @click="$emit('close_sidebar')" v-else class="item">
          <img src="@/assets/icons/header/user.svg" alt="" :style="language=='en' ? { marginRight: '5px !important' } : {marginLeft: '5px !important'}">
          <router-link class="listItem " to="/MyAccount">{{ $t('My Account') }}</router-link>
      </div>
      <div class="lang">
        <img src="@/assets/icons/header/lang.svg" alt="" :style="language=='en' ? { marginRight: '3px !important' } : {marginLeft: '3px !important'}">
        <a class="listItem" @click="selected_language='ar'" v-if="language=='en'" href="#">{{ $t('English') }}</a>
        <a class="listItem" @click="selected_language='en'" v-if="language=='ar'" href="#">{{ $t('Arabic') }}</a>
      </div>
      <div class="container2">
        <span class="active2">{{ $t('Follow us') }}:</span>
        <div class="social-media2">
          <a target="blank" :href="contacts.instagram"><img src="@/assets/icons/header/instagram.svg" alt="Instagram Icon"></a>
          <a target="blank" :href="contacts.facebook"><img src="@/assets/icons/header/facebook.svg" alt="facebook Icon"></a>
          <a target="blank" :href="contacts.x"><img src="@/assets/icons/header/x.svg" alt="x Icon"></a>
        </div>
      </div>
  </div>
</template>

<script>
import store from '@/store'
import langStor from '@/langStore'
import { i18n } from '@/lang';

export default {
  name: "AppHeader",
  props:['hideAll'],
  setup(){
    function createDebounce() {
            let timeout = null;
            return function (fnc, delayMs) {
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    fnc();
                }, delayMs || 500);
            };
        }
        return{
          debounce: createDebounce(),
        }
  },
  data: function () {
    return {
      categories: [],
      contacts: {},
      searchValue:'',
      openSide:false,
      selected_language:localStorage.getItem('language')
    };
  },
  methods: {
    getCategories() {
      fetch(this.serverURL + 'api/catalog/categories', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
            this.categories = data.data;
        })
        .catch(error => {
          console.log(error);
        })
    },
    getContacts() {
      fetch(this.serverURL + 'api/contacts', {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${store.state.accessToken}`,
        },
      })
        .then(response => response.json())
        .then(data => {
          if(data.status == 200)
          {
            this.contacts.whatsapp = data.data.filter(item => { return item.type == 'whatsapp' })[0].value
            this.contacts.facebook = data.data.filter(item => { return item.type == 'facebook' })[0].value
            this.contacts.x = data.data.filter(item => { return item.type == 'x' })[0].value
            this.contacts.instagram = data.data.filter(item => { return item.type == 'instagram' })[0].value
            this.contacts.contact = data.data.filter(item => { return item.type == 'contact' })[0].value
            this.contacts.email = data.data.filter(item => { return item.type == 'email' })[0].value
          }
          console.log(this.contacts);
        })
        .catch(error => {
          console.log(error);
        })
    }
  },
  beforeMount() {
    this.getCategories();
    this.getContacts();
  },
  mounted() {
    let that = this;

    //  Sticky Menu
    var $window = window.$(window);
    $window.on("scroll", function () {
      if ($window.scrollTop() > 20) {
        window.$(".header").addClass("sticky");
      } else {
        window.$(".header").removeClass("sticky");
      }
    });

    // On Link Clicked
    window.$('.header .navbar ul li:not(:nth-child(2))').on('click', function() {
      window.$(this).addClass('active');
      window.$(this).siblings().removeClass('active');
      window.$('.header .navbar ul .dropdown').removeClass('active');
    });
    window.$('.mobile_nav .listItem').on('click', function() {
      console.log('aaaa');
      window.$(this).addClass('active2');
      window.$(this).siblings().removeClass('active');
      window.$('.header .navbar ul .dropdown').removeClass('active');
    });

    // On DropDown Link Clicked
    window.$('body').on('click', '.header .navbar .dropdown a', function() {
      window.$('.header .navbar ul li').removeClass('active');
      window.$('.header .navbar ul .dropdown').addClass('active');

      localStorage.setItem('category_id', window.$(this).data('id'));
      
      let fullPath = '/Category/' + window.$(this).data('name').replaceAll(' ', '-');
      that.$router.push({ path: fullPath });
    });

    window.$('body').on('click', '.dropdown a', function() {
      console.log('<<<<<<<<<<<<<<<<<<<<<<<');
      window.$('.header .navbar ul li').removeClass('active');
      window.$('.dropdown').addClass('active');

      localStorage.setItem('category_id', window.$(this).data('id'));
      
      let fullPath = '/Category/' + window.$(this).data('name').replaceAll(' ', '-');
      console.log(fullPath,'>>');
      that.$router.push({ path: fullPath });
      that.$emit('close_sidebar')
    });

    // On Search Input
    // window.$('.search input').on('input', function (e){
    //   if (e.target.value != ''){
    //     let fullPath = '/SearchResults/' + e.target.value;
    //     that.$router.push({ path: fullPath });
    //   }else {
    //     that.$router.push({ name: 'Home' });
    //   }
    // });
  },
  computed: {
    token () {
      return store.state.accessToken;
    },
    lang () {
      return store.state.appLang;
    },
    language(){
      return langStor.state.language
    }
  },
  watch:{
    selected_language(new_lang){
      console.log(new_lang);
          localStorage.setItem('language' , new_lang );
          document.documentElement.setAttribute('lang', new_lang);
          document.documentElement.dataset.direction = ['ar'].includes(new_lang)?'rtl':'ltr';
          i18n.setLocale(new_lang);
          langStor.actions.setLanguage(new_lang);
          document.documentElement.style.direction=['ar'].includes(new_lang)?'rtl':'ltr';
      },
      hideAll(_new){
        if(_new==false){
          this.openSide=false
        }
      },
      searchValue(_new){
        if (_new != ''){
          let fullPath = '/SearchResults/' + _new;
          this.$router.push({ path: fullPath });
        }else {
          this.$router.push({ name: 'Home' });
        }
      }
  },
};
</script>

<style>
.header.sticky{
  position: sticky;
  width: 100%;
  top: 0;
  margin-top: 0;
  z-index: 99;
  display: unset
}
/* Top Bar */
.header .top-bar{
  background-color: #F7F6E2;
  padding: .5rem 0;
}
.mobile_nav{
  display: none;
}
.header .top-bar .container{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.mobile_cart{
  display: none;
}
.mobile_cart img{
  margin-right: 5px
}
.header .top-bar span{
  color: #6F825F;
  font-size: 14px;
  font-weight: bold;
}
.header .top-bar .social-media{
  display: flex;
  justify-content: space-evenly;
  width: 6rem
}

/* Middle Bar */
.header .middle-bar{
  padding: 1rem 0;
  background-color: #FFF;
}
.header .middle-bar .container .row{
  align-items: center;
}
.search{
  display: flex;
  justify-content: flex-start;
}
.search input{
  border: 1px solid #B2CC9B;
  padding: .5rem;
  border-radius: 10px;
  width: 15rem;
}
.header .middle-bar .logo{
  width: 6rem;
}
.header .middle-bar .settings{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header .middle-bar .settings .item{
  display: flex;
  align-items: center;
}
.header .middle-bar .settings .item img{
  margin-right: .5rem
}
[data-direction = rtl] .header .middle-bar .settings .item img  {
    margin-left: .5rem;
}
.header .middle-bar .settings .item a{
  text-decoration: none;
  color: #2B2B2B;
  font-size: 16px;
  font-weight: bold;
}

/* Navbar */
.header .navbar{
  background-color: #B2CC9B;
}
.header .navbar ul{
  list-style-type: none;
  margin: auto;
  padding: 0;
  width: 50%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.header .navbar ul a,
.dropdown span{
  font-size: 18px;
  font-weight: 500;
}
.header .navbar ul a{
  text-decoration: none;
  color: #FFF;
}
.header .navbar ul li:hover a,
.header .navbar ul li.active a,
.dropdown:hover span,
.dropdown.active span{
  color: #6F825F;
}
.dropdown {
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 1rem;
  z-index: 99;
  border-radius: .2rem;
}
.dropdown-content a{
  display: block;
  color: #6F825F !important;
  font-size: 14px;
  font-weight: 400;
}
.dropdown-content a:hover{
  background-color: #b2cc9bc4;
}
.dropdown:hover .dropdown-content {
  display: block;
}

@media(max-width: 767px){
.search{
    margin-bottom: 1rem;
  }
  .header .middle-bar .settings{
    margin-top: 1rem;
  }
  .header{
    display: none;
  }
  .header.sticky{
    display: none;
  }
  .mobile_header{
    width: 100%;
    padding: 1rem 1rem;
    background-color: #FFF;
    position: sticky;
    width: 100%;
    top: 0;
    margin-top: 0;
    z-index: 99;
    display: flex;
    justify-content: space-between;
    align-items: center
  }
  .mobile_nav{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 1rem 1rem;
    background-color: #FFF;
    position: sticky;
    width: 100%;
    top: 0;
    margin-top: 0;
    z-index: 99;
  }
  .mobile_header .item{
    display: flex;
    align-items: center;
  }
  .logo{
    margin-right: -2rem;
  }
  .mobile_header .item img{
    /* margin-right: .5rem */
  }
  [data-direction = rtl] .mobile_header .item img  {
      margin-left: 3px !important;
  }
  .mobile_header .item a{
    text-decoration: none;
    color: #2B2B2B;
    font-size: 16px;
    font-weight: bold;
  }
  .logo{
    width: 4rem;
  }
  .sidebar{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    padding: 0 20px;
    margin-top: 50px
  }
  .active2{
    color: #6F825F;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 25px !important;
    text-decoration: unset
  }
  .listItem{
    color: black;
    text-decoration: unset;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 25px !important;
  }
  [data-direction = rtl] .listItem {
    color: black;
    text-decoration: unset;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
  }
  .active2 {
    color: #6F825F;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 25px !important;
    text-decoration: unset
  }
  [data-direction = rtl].mobile_cart{
    margin-left: 2px !important
  }
  .lang a{
    color: black;
    text-decoration: unset
  }
  .container2{
    display: flex;
    align-items: center;
    gap: 10px
  }
  .social-media2{
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .mobile_cart{
    display: flex;
    align-items: center
  }
  .search input{
    border: 1px solid #B2CC9B;
    padding: .5rem;
    border-radius: 10px;
    width: 100% !important;
  }
  [data-direction = rtl] .logo{
    margin: 0 !important;
    margin-right: 0px !important;
  }
  
}

</style>
